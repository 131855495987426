import {takeEvery, call, put} from 'redux-saga/effects'
import * as t from '../const.config'
import Axios from '../../utils/server/fetch'
import qs from 'qs'

export const watcherEvent = [
    takeEvery(t.EVENT_GET_LOADING, workerEventGet),
    takeEvery(t.RSVP_GET_LOADING, getRSVP),
    takeEvery(t.ACC_RSVP_GET_LOADING, approveRSVP),
    takeEvery(t.EVENT_UPDATE_LOADING, workerEventUpdate),
    takeEvery(t.EVENT_DETAIL_LOADING, workerEventGetDetail),
    takeEvery(t.EVENT_POST_LOADING, workerEventPost),
    takeEvery(t.EVENT_DELETE_LOADING, workerEventRemove)
]

const getEvent = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_getevents&search=${body.search}`,
            data:qs.stringify({
                ACCESS_TOKEN:body.token,
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const rsvp = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_getrsvp`,
            data:body
        })
        return data
    } catch (error) {
        return error
    }
}

const approved = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_approversvp`,
            data:body
        })
        return data
    } catch (error) {
        return error
    }
}

const getEventDetail = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_getevents&event_id=${body.data_id}`,
            data:qs.stringify({
                ACCESS_TOKEN:body.token,
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const newEvent = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_newevent`,
            data:body
        })
        return data
    } catch (error) {
        return error
    }
}

const updateEvent = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_updateevent`,
            data:body
        })
        return data
    } catch (error) {
        return error
    }
}

const removeEvent = async body => {
    const form = new FormData()
    form.append('ACCESS_TOKEN', body.token)
    form.append('post_id', body.id)
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_deleteevent`,
            data:form
        })
        return data
    } catch (error) {
        return error
    }
}

function* workerEventGet(action){
    try{
        const response = yield call(getEvent, action.payload)
        if(response.status === 200){
            yield put({
                type:t.EVENT_GET_SUCCSESS,
                data: response.data.result
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.EVENT_GET_FAILURE,
            message:error.response.data.message
        })
    }
}

function* getRSVP(action){
    try{
        const response = yield call(rsvp, action.payload)
        console.log(response, "ini apa ya")
        if(response.status === 200){
            yield put({
                type:t.RSVP_GET_SUCCSESS,
                data: response.data.result
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.RSVP_GET_FAILURE,
            message:error.response.data.message
        })
    }
}

function* approveRSVP(action){
    try{
        const response = yield call(approved, action.payload)
        if(response.status === 200){
            yield put({
                type:t.ACC_RSVP_GET_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.ACC_RSVP_GET_FAILURE,
            message:error.response.data.message
        })
    }
}

function* workerEventGetDetail(action){
    try{
        const response = yield call(getEventDetail, action.payload)

        if(response.status === 200){
            yield put({
                type:t.EVENT_DETAIL_SUCCSESS,
                detail: response.data.result
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.EVENT_DETAIL_FAILURE,
            message:error.response.data.message
        })
    }
}

function* workerEventPost(action){
    try{
        const response = yield call(newEvent, action.payload)
        if(response.status === 200){
            yield put({
                type:t.EVENT_POST_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        console.log(error, "pesan errornya")
        yield put({
            type: t.EVENT_POST_FAILURE,
            message:error.response.data.message
        })
    }
}

function* workerEventUpdate(action){
    try{
        const response = yield call(updateEvent, action.payload)
        if(response.status === 200){
            yield put({
                type:t.EVENT_UPDATE_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.EVENT_UPDATE_FAILURE,
            message:error.response.data.message
        })
    }
}

function* workerEventRemove(action){
    try{
        const response = yield call(removeEvent, action.payload)
        if(response.status === 200){
            yield put({
                type:t.EVENT_DELETE_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.EVENT_DELETE_FAILURE,
            message:error.response.data.message
        })
    }
}
