import React from 'react';
import { Modal, Form, Input, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';


const ModalEditAlbum = ({ visible, onCreate, onCancel, title, desc }) => {
  const [form] = Form.useForm();
  
  return (
    <Modal
      visible={visible}
      title="Edit Album"
      okText="Update"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item
          name="subject"
          rules={[
            {
              required: true,
              message: 'Please input the title of Album!',
            },
          ]}
        >
          <Input defaultValue={title} placeholder="Album" />
        </Form.Item>
        <Form.Item name="content" >
          <Input.TextArea defaultValue={desc} placeholder="Description" type="textarea" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export {ModalEditAlbum}