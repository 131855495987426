import {takeEvery, call, put} from 'redux-saga/effects'
import * as t from '../const.config'
import Axios from '../../utils/server/fetch'
import qs from 'qs'

export const watcherArticles = [
    takeEvery(t.ARTICLES_GET_LOADING, workerArticlesGet),
    takeEvery(t.ARTICLES_POST_LOADING, workerArticlePost),
    takeEvery(t.ARTICLES_UPDATE_LOADING, workerArticleUpdate),
    takeEvery(t.ARTICLE_DETAIL_LOADING, workerArticleDetail),
    takeEvery(t.ARTICLE_DELETE_LOADING, workerArticleDelete),
]

const getArticles = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_getarticles&search=${body.search}`,
            data:qs.stringify({
                ACCESS_TOKEN:body.token,
            })
        })
        return data
    } catch (error) {
        return error
    }
}
const postArticles = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_newarticle`,
            data:body
        })
        return data
    } catch (error) {
        return error
    }
}
const updateArticles = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_updatearticle`,
            data:body
        })
        return data
    } catch (error) {
        return error
    }
}
const getArticleDetail = async body => {
    try{
        const data = await Axios({
            url:`/api/getpost/?post_id=${body.post_id}`,
            data:qs.stringify({
                ACCESS_TOKEN:body.token,
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const deleteArticles = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_deletearticle`,
            data:qs.stringify({
                ACCESS_TOKEN:body.token,
                post_id:body.id,
            })
        })
        return data
    } catch (error) {
        return error
    }
}

function* workerArticlesGet(action){
    try{
        const response = yield call(getArticles, action.payload)
        if(response.status === 200){
            yield put({
                type:t.ARTICLES_GET_SUCCSESS,
                data: response.data.result
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.ARTICLES_GET_FAILURE,
            message:error.response.data.message
        })
    }
}

function* workerArticlePost(action){
    try{
        const response = yield call(postArticles, action.payload)
        if(response.status === 200){
            yield put({
                type:t.ARTICLES_POST_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.ARTICLES_POST_FAILURE,
            message:error.response.data.message
        })
    }
}

function* workerArticleUpdate(action){
    try{
        const response = yield call(updateArticles, action.payload)
        if(response.status === 200){
            yield put({
                type:t.ARTICLES_UPDATE_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.ARTICLES_UPDATE_FAILURE,
            message:error.response.data.message
        })
    }
}

function* workerArticleDetail(action){
    try{
        const response = yield call(getArticleDetail, action.payload)
        if(response.status === 200){
            yield put({
                type:t.ARTICLE_DETAIL_SUCCSESS,
                detail: response.data.result
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.ARTICLE_DETAIL_FAILURE,
            message:error.response.data.message
        })
    }
}

function* workerArticleDelete(action){
    try{
        const response = yield call(deleteArticles, action.payload)
        console.log(response, "ini datanya")
        if(response.status === 200){
            yield put({
                type:t.ARTICLE_DELETE_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.ARTICLE_DETAIL_FAILURE,
            message:error.response.data.message
        })
    }
}
