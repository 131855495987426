import React from 'react'
import { Table, Row, Col, Button, message, Input, Divider, Spin, List, Card, Avatar } from 'antd'
import { MoreOutlined } from '@ant-design/icons';

import {Header, Profile} from '../../components'
import { useDispatch, useSelector } from 'react-redux';
import {memberGetWatcher} from '../../redux/actions'
import { useHistory } from 'react-router-dom';
import * as URL from '../../utils/routers/constant'
import * as TYPE from '../../redux/const.config'
// import ReactPlayer from 'react-player'

function Member() {
  const history = useHistory()
  const dispatch = useDispatch()
  const getArticles = useSelector(state => state.member)
  const user = useSelector(state => state.user)
  
  React.useEffect(()=> {
    let body = {
      token:user.user.access_token_data?.token
    }
    dispatch(memberGetWatcher(body))
  },[])


  const onSearch = value => console.log(value);
  
    return (
        <div>
          {console.log(getArticles.data[0], "ini data")}
            <Header />
            <div className="site-layout" style={{ paddingTop: '100px' }}>
              <Row align="top" gutter={[24,16]}>
                <Col span={6}>
                  <Profile />
                </Col>
                <Col span={12}>
                    <div style={{backgroundColor:'white', marginTop:40, borderRadius:10}}>
                        <div style={{ padding:20, display:'flex', justifyContent:"space-between"}}>
                            <h3 style={{fontWeight:'bold'}}>Member</h3>
                            {/* <Input.Search style={{width:'35%'}} placeholder="search member" onSearch={onSearch} enterButton /> */}
                        </div>
                        <Divider/>
                        <List
                          style={{paddingLeft:15, paddingRight:15}}
                          grid={{ gutter: 16, column: 2 }}
                          dataSource={getArticles.data}
                          renderItem={item => (
                            <List.Item>
                              <List.Item.Meta
                               avatar={<Avatar src={item.avatar} />}
                               title={item.display_name}
                              />
                            </List.Item>
                          )}
                        />
                    </div>
                </Col>
              </Row>
              </div>
        </div>
    )
}

export {Member}
