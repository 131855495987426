import React from 'react';
import { Modal, Form, Input, Divider, DatePicker, TimePicker,  Switch, Select, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { city, getProfile, provinsi } from '../../redux/actions';
// import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
// import * as CONST from '../utils/server/const'

const { Option } = Select;


const EditAccount = ({ visible, onCreate, onCancel, imageUpload }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const [provID, setProvID] = React.useState("")
  const list = useSelector(state => state.list)
  const user = useSelector(state => state.user)
  const profile = useSelector(state => state.profile.user)

  function handleChange(value) {
    setProvID(value)
  }

  React.useEffect(() => {
    dispatch(provinsi())
    dispatch(city(provID))
  },[provID])

  React.useEffect(() => {
    const body = new FormData()
    body.append("ACCESS_TOKEN",user.user.access_token_data?.token)
    dispatch(getProfile(body))
  },[])
  
  return (
    <Modal
      visible={visible}
      title="Edit Account"
      okText="Update"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      {console.log(profile, "data prodile")}
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item
          name="display_name"
        >
          <Input placeholder="Display Name" defaultValue={profile.community_name} />
        </Form.Item>
        <Form.Item
         name="email"
         
        >
            <Input placeholder="Email" defaultValue={profile.email}/>
        </Form.Item>
        <Form.Item
          name="phone_number"
        >
          <Input placeholder="Phone Number" defaultValue={profile.phone_number} />
        </Form.Item>
        <Divider />
          <Form.Item 
            name="description"
          >
            <Input.TextArea placeholder="About" defaultValue={profile.description}  />
          </Form.Item>
          <Form.Item
            name="category"
          >
            <Select placeholder="Category">
                {list.data.map(item => {
                    return <Option value={item.term_id}>{item.name}</Option>
                })}
            </Select>
          </Form.Item>
          <Input.Group size="large">
              <Row gutter={8}>
                  <Col span={12}>
                      <Form.Item
                          name="province"
                      >
                              <Select placeholder="Province" onChange={handleChange}>
                                  {list.province.map(item => {
                                      return <Option value={item.id_prov}>{item.nama}</Option>
                                  })}
                              </Select>
                      </Form.Item>
                  </Col>
                  <Col span={12}>
                      <Form.Item
                          name="city"
                      >
                          <Select placeholder="City">
                              {list.city.map(item => {
                                  return <Option value={item.id_city}>{item.nama}</Option>
                              })}
                          </Select>
                      </Form.Item>
                  </Col>
              </Row>
          </Input.Group>
      </Form>
    </Modal>
  );
};

export {EditAccount}