export const Home = '/'

export const SignIn = '/sign-in'
export const SignUp = '/sign-up'
export const Forget = '/lostpwd'
export const ForgetPwd = '/forget-password'

export const Event = '/event'
export const RSVP = '/rsvp'
export const Articles = '/article'
export const ArticleNew = '/newarticle'
export const Podcast = '/podcast'
export const Photo = '/photos'
export const Member = '/member'
export const Store = '/store'


export const Profile = '/profile'