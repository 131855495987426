import React from 'react';
import { Modal, Form, Input } from 'antd';
// import { LoadingOutlined, PlusOutlined,InboxOutlined } from '@ant-design/icons';

const ModalUpdatePodcast = ({ visible, onCreate, onCancel, imageUpload, audioUpload, title, desc }) => {
  const [form] = Form.useForm();

  const handleChange = e => {
    if (e.target.files.length) {
      imageUpload(e.target.files[0])
    }
  }
  const audioChange = e => {
    if (e.target.files.length) {
      audioUpload(e.target.files[0])
    }
  }
  
  return (
    <Modal
      visible={visible}
      title="Update Podcast"
      okText="Post"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
      <Form.Item
        name="audio"
      >
        <Input type="file" onChange={audioChange} />
      </Form.Item>
        <Form.Item
          name="subject"
        >
          <Input placeholder="Title" defaultValue={title} />
        </Form.Item>
        <Form.Item name="content" >
          <Input placeholder="Speaker" defaultValue={desc} />
        </Form.Item>
          <p>Thumbnail</p>
        <Form.Item name="file">
          <Input type="file" onChange={handleChange}/>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export {ModalUpdatePodcast}