import React from 'react'
import {Row, Col, Form, Input, Button, Checkbox, Select, Divider, message} from 'antd'
import { UserOutlined, LockOutlined, MailOutlined, DeploymentUnitOutlined } from '@ant-design/icons'
import {useDispatch, useSelector} from "react-redux"
import {images} from '../../res'
import { Link, useHistory } from 'react-router-dom'
import * as URL from '../../utils/routers/constant'
import { category, city, provinsi, signUpWatcher } from '../../redux/actions'
import * as t from '../../redux/const.config'

const { Option } = Select;

function SignUp() {
    const [checked, setChecked] = React.useState(false)
    const [provID, setProvID] = React.useState("")
    const dispatch = useDispatch()
    const data = useSelector(state => state.user)
    const list = useSelector(state => state.list)
    const history = useHistory()

    React.useEffect(() => {
        if(data.action === t.SIGN_UP_SUCCSESS){
            history.push(URL.Event)
        } else if (data.action === t.SIGN_UP_FAILURE){
            // console.log(data.message)
            message.error(data.message)
        }
    })

    React.useEffect(() => {
        dispatch(category())
    },[])

    React.useEffect(() => {
        dispatch(provinsi())
        dispatch(city(provID))
    },[provID])

    const onChange = e => {
        // console.log('checked = ', e.target.checked);
        setChecked(e.target.checked)
      };

    const onFinish = (values) => {
        dispatch(signUpWatcher(values))
    //   console.log('Received values of form: ', values);
    };

    function handleChange(value) {
        setProvID(value)
        console.log(value)
    }
    return (
        <Row gutter={[16, 16]} style={{height:'100vh', padding:100, marginRight:100, marginLeft:100}}>
            <Col span={12} offset={4}>
                <div style={{padding:8, width:'75%'}}>
                    <img alt="logo" src={images.logo.default}/>
                    <h2 style={{marginTop:20}}>
                    Connect all communities for stronger nation
                    </h2>
                    <div style={{padding:20}}>
                        <a href="https://play.google.com/store/apps/details?id=com.socialconnext&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                        <img src={images.download.android.default} style={{marginRight:25}}/>
                        </a>
                        {/* <img src={images.download.ios.default}/> */}
                    </div>
                </div>
            </Col>
            <Col span={8}>
            <Form
                name="normal_login"
                size="large"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <div
                    style={{backgroundColor:'white', padding:20, borderRadius:10}}
                >
                    <div>
                        <h1 style={{margin:0}}>
                        Create a new account
                        </h1>
                        <p>
                        Manage your community professionally
                        </p>
                    </div>
                    <Form.Item
                        name="display_name"
                        rules={[
                            {
                                required:true,
                                message:"Please input your display commmunity"
                            }
                        ]}
                        >
                            <Input 
                                prefix={<DeploymentUnitOutlined className="site-form-item-icon" />} 
                                placeholder="Community" 
                            />
                        </Form.Item>
                        <Divider />
                        <Form.Item
                            name="categories"
                            rules={[
                                {
                                    required:true,
                                    message:"Please select Category",
                                },
                            ]}>
                                <Select placeholder="Category">
                                    {list.data.map(item => {
                                        return <Option value={item.term_id}>{item.name}</Option>
                                    })}
                                </Select>
                        </Form.Item>
                        <Form.Item
                            name="province"
                            rules={[
                                {
                                    required:true,
                                    message:"Please select Province",
                                },
                            ]}>
                                <Select placeholder="Province" onChange={handleChange}>
                                    {list.province.map(item => {
                                        return <Option value={item.id_prov}>{item.nama}</Option>
                                    })}
                                </Select>
                        </Form.Item>
                        <Form.Item
                            name="city"
                            rules={[
                                {
                                    required:"last_name",
                                    message:"Please input your last name"
                                }
                            ]}
                        >
                            <Select placeholder="City">
                                {list.city.map(item => {
                                    return <Option value={item.id_city}>{item.nama}</Option>
                                })}
                            </Select>
                        </Form.Item>
                        <Divider />
                        {/* <Input.Group size="large">
                            <Row gutter={0}>
                                <Col span={12}>
                                </Col>
                                <Col span={12}>
                                </Col>
                            </Row>
                        </Input.Group> */}
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Email can't be empty!"
                            },
                            {
                                type: "email",
                                message: "The input is not valid E-mail!"
                            }
                        ]}
                    >
                        <Input 
                            prefix={<MailOutlined className="site-form-item-icon" />} 
                            placeholder="Email" 
                        />
                    </Form.Item>
                    <Form.Item
                        name="username"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your Username!',
                        },
                        {
                            pattern:/^.{4,}$/,
                            message: 'Username minimal 4 characters',
                        },
                        ]}
                    >
                        <Input 
                            prefix={<UserOutlined className="site-form-item-icon" />} 
                            placeholder="Username" 
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                        {
                            pattern:/^.{8,}$/,
                            message: 'Minimal password 6 characters!',
                        }
                        ]}
                        hasFeedback
                    >
                        <Input.Password 
                            prefix={<LockOutlined className="site-form-item-icon" />} 
                            placeholder="New Password"
                        />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            {
                                pattern:/^.{8,}$/,
                                message: 'Minimal password 6 characters!',
                            },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }

                            return Promise.reject('The two passwords that you entered do not match!');
                            },
                        }),
                        ]}
                    >
                        <Input.Password 
                            prefix={<LockOutlined className="site-form-item-icon" />} 
                            placeholder="Confirm Password"
                        />
                    </Form.Item>
            
                    <Form.Item>
                        <Checkbox
                            style={{marginBottom:10}}
                            checked={checked}
                            onChange={onChange}
                        >
                            By clicking Sign Up, you agree to our <a href="https://docs.google.com/document/d/1WRvIlyyBnOULMvFckJwJqfuj7GDYPCqzdvr0Ym_C3tE/edit?usp=sharing" target="_blank">terms and data policy.</a>
                        </Checkbox>
                        <Button loading={data.action === t.SIGN_UP_LOADING} disabled={!checked} style={{justifyContent:'center', width:'100%'}} type="primary" htmlType="submit" className="login-form-button">
                            Sign Up
                        </Button>
                    </Form.Item>         
                </div>
                <div style={{marginTop:12, textAlign:'center'}}>
                <Link to={URL.SignIn}>Log In</Link> to Existing Account 
                    
                </div>
            </Form>
            </Col>
        </Row>
    );
}

export {SignUp}
