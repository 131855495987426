import {takeEvery, call, put} from 'redux-saga/effects'
import * as t from '../const.config'
import Axios from '../../utils/server/fetch'
import qs from 'qs'

export const watcherPhotos = [
    takeEvery(t.PHOTOS_GET_LOADING, workerAlbumGet),
    takeEvery(t.IMAGE_GET_LOADING, workerImageGet),
    takeEvery(t.PHOTOS_POSTALBUM_LOADING, workerAlbumPost),
    takeEvery(t.PHOTOS_POST_UPDATE_LOADING, workerAlbumUpdate),
    takeEvery(t.PHOTOS_POST_DELETE_LOADING, workerAlbumDelete),
    takeEvery(t.IMAGE_POST_LOADING, workerPostImage)
]

const getAlbum = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_getalbums`,
            data:qs.stringify({
                ACCESS_TOKEN:body.token,
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const postAlbum = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_newalbum`,
            data:qs.stringify({
                ACCESS_TOKEN:body.token,
                subject:body.subject,
                content: body.content
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const getImage = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_getalbums`,
            data:qs.stringify({
                ACCESS_TOKEN:body.token,
                id:body.id
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const postImage = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_add_image_album`,
            data:body
        })
        return data
    } catch (error) {
        return error
    }
}

const updateAlbum = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_updatealbum`,
            data:qs.stringify({
                ACCESS_TOKEN:body.token,
                subject:body.subject,
                content: body.content,
                id:body.id
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const deleteAlbum = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_deletealbum`,
            data:qs.stringify({
                ACCESS_TOKEN:body.token,
                post_id:body.id
            })
        })
        return data
    } catch (error) {
        return error
    }
}

function* workerAlbumGet(action){
    try{
        const response = yield call(getAlbum, action.payload)
        if(response.status === 200){
            yield put({
                type:t.PHOTOS_GET_SUCCSESS,
                data: response.data.result
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.PHOTOS_GET_FAILURE,
            message:error.response.data.message
        })
    }
}

function* workerImageGet(action){
    try{
        const response = yield call(getImage, action.payload)
        if(response.status === 200){
            yield put({
                type:t.IMAGE_GET_SUCCSESS,
                image: response?.data?.result[0].album.images
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.IMAGE_GET_FAILURE,
            message:error.response?.data?.message
        })
    }
}
function* workerAlbumPost(action){
    try{
        const response = yield call(postAlbum, action.payload)
        if(response.status === 200){
            yield put({
                type:t.PHOTOS_POSTALBUM_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.PHOTOS_POSTALBUM_FAILURE,
            message:error.response.data.message
        })
    }
}

function* workerAlbumUpdate(action){
    try{
        const response = yield call(updateAlbum, action.payload)
        if(response.status === 200){
            yield put({
                type:t.PHOTOS_POST_UPDATE_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.PHOTOS_POST_UPDATE_FAILURE,
            message:error.response.data.message
        })
    }
}

function* workerAlbumDelete(action){
    try{
        const response = yield call(deleteAlbum, action.payload)
        if(response.status === 200){
            yield put({
                type:t.PHOTOS_POST_DELETE_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.PHOTOS_POST_DELETE_FAILURE,
            message:error.response.data.message
        })
    }
}

function* workerPostImage(action){
    try{
        const response = yield call(postImage, action.payload)
        if(response.status === 200){
            yield put({
                type:t.IMAGE_POST_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.IMAGE_POST_FAILURE,
            message:error.response.data.message
        })
    }
}