import React from 'react';
import { Modal, Form, Input, Divider, DatePicker, TimePicker,  Switch } from 'antd';
// import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
// import * as CONST from '../utils/server/const'

const EditEvent = ({ visible, onCreate, onCancel, title, deskripsi, lokasi  }) => {
  const [form] = Form.useForm();
  const [free, setFree] = React.useState(false)


  function onChange(checked) {
    setFree(checked)
  }
  
  return (
    <Modal
      visible={visible}
      title="Edit Event"
      okText="Update"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item
          name="subject"
          rules={[
            {
              required: true,
              message: 'Please input the title of Event!',
            },
          ]}
        >
          <Input defaultValue={title} placeholder="Title" />
        </Form.Item>
        <Form.Item
          name="content"
          rules={[
            {
              required: true,
              message: 'Please input description of Event!',
            },
          ]}
        >
          <Input.TextArea defaultValue={deskripsi} placeholder="Description" type="textarea" />
        </Form.Item>
        <Form.Item
         name="lokasi"
         rules={[
          {
            required: true,
            message: 'Please input location of Event!',
          },
        ]}
        >
            <Input defaultValue={lokasi} placeholder="Location"/>
        </Form.Item>
        <Divider />
        <div style={{display:'flex'}}>
          <Form.Item 
            style={{marginRight:10}} 
            name="tanggal_event"
            rules={[
              {
                required: true,
                message: 'Please input date of Event!',
              },
            ]}
          >
            <DatePicker placeholder="Tanggal" />
          </Form.Item>
          <Form.Item
            name="waktu_mulai"
            rules={[
              {
                required: true,
                message: 'Please input time of Event!',
              },
            ]}
          >
            <TimePicker format="HH:mm" placeholder="Start" />
          </Form.Item>
        </div>

        <Form.Item>
          <Switch onChange={onChange} /> Free Event
        </Form.Item>
        
        <Form.Item name="ticket_price">
            <Input disabled={free} placeholder="Price"/>
        </Form.Item>
        <Form.Item name="payment_info">
            <Input disabled={free} placeholder="Nama Bank - No Rekening"/>
        </Form.Item>
        <Form.Item name="payment_pic">
            <Input disabled={free} placeholder="Nama Rekening"/>
        </Form.Item>
        <p>Thumbail</p>
        <Form.Item name="file">
          <Input type="file" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export {EditEvent}