import * as t from '../const.config'

const initialState = {
    user:{},
    action:null,
    loading:false
}

const reducerProfile = (state=initialState, action) => {
    switch(action.type){
        case t.PROFILE_GET_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.PROFILE_GET_SUCCSESS:
            return{
                ...state,
                action:action.type,
                user:action.user,
                loading:false
            }
        case t.PROFILE_GET_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.PROFILE_UPDATE_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.PROFILE_UPDATE_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.PROFILE_UPDATE_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.COVER_UPDATE_LOADING:
            return{
                ...state,
                action:action.type,
                loading:false
            }
        case t.COVER_UPDATE_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.COVER_UPDATE_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.PHOTO_PROFILE_UPDATE_LOADING:
            return{
                ...state,
                action:action.type,
                loading:false
            }
        case t.PHOTO_PROFILE_UPDATE_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.PHOTO_PROFILE_UPDATE_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        default:
            return state
    }
}

export {reducerProfile}