import { CATEGORY_IN_LOADING, CITY_IN_LOADING, PROVINCE_IN_LOADING } from "../const.config";

export const category = () =>({
    type: CATEGORY_IN_LOADING
})

export const provinsi = () =>({
    type: PROVINCE_IN_LOADING
})

export const city = (data) =>({
    type: CITY_IN_LOADING,
    payload: data
})