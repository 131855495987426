import * as t from '../const.config'

const initialState = {
    data:[],
    image:[],
    action:null,
    loading:false
}

const reducerPhotos = (state=initialState, action) => {
    switch(action.type){
        case t.PHOTOS_GET_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.PHOTOS_GET_SUCCSESS:
            return{
                ...state,
                action:action.type,
                data:action.data,
                loading:false
            }
        case t.PHOTOS_GET_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.IMAGE_GET_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.IMAGE_GET_SUCCSESS:
            return{
                ...state,
                action:action.type,
                image:action.image,
                loading:false
            }
        case t.IMAGE_GET_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.PHOTOS_POSTALBUM_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.PHOTOS_POSTALBUM_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.PHOTOS_POST_UPDATE_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.PHOTOS_POST_UPDATE_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.PHOTOS_POST_DELETE_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.PHOTOS_POST_DELETE_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.IMAGE_POST_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.IMAGE_POST_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        default:
            return state
    }
}

export {reducerPhotos}