import * as t from '../const.config'

const initialState = {
    user:{},
    action:null,
    loading:false
}

const reducerUser = (state=initialState, action) => {
    switch(action.type){
        case t.SIGN_IN_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.SIGN_IN_SUCCSESS:
            return{
                ...state,
                action:action.type,
                user:action.user,
                loading:false
            }
        case t.SIGN_IN_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.SIGN_UP_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.SIGN_UP_SUCCSESS:
            return{
                ...state,
                action:action.type,
                user:action.user,
                loading:false
            }
        case t.SIGN_UP_FAILURE:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.FORGET_PASSWORD_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.FORGET_PASSWORD_FAILURE:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.NEW_PASSWORD_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.NEW_PASSWORD_FAILURE:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }

        case t.VALIDATE_PASSWORD_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                user:action.data,

                loading:false
            }
        case t.VALIDATE_PASSWORD_FAILURE:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.SIGN_OUT:
            return{
                ...state,
                action:action.type,
                user:null
            }
        default:
            return state
    }
}

export {reducerUser}