import React from 'react'
import { Input, Avatar, Menu, Row, Col, Card, Button, Image } from 'antd'
import { UserOutlined,PlusCircleOutlined, BellOutlined, MessageOutlined,SettingOutlined,EditOutlined,EllipsisOutlined } from '@ant-design/icons';
import {images, colors} from '../../res'
import {Header} from '../../components'
const { Search } = Input;

function Home() {
    const onSearch = value => console.log(value);
    return (
        <div>
            <Header />
            <div className="site-layout" style={{ paddingTop: '100px' }}>
              <Row align="top" gutter={[24,16]}>
                <Col span={6} style={{padding:'0 32px'}}>
                  <Card 
                    title="Gallery" 
                    extra={<PlusCircleOutlined />} 
                    style={{ width: 'auto', marginTop:20 }}
                  >
                    <Image
                      width={'33%'}
                      src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                    />
                    
                    <Image
                      width={'33%'}
                      src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                    />
                    <Image
                      width={'33%'}
                      src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                    />
                    <Image
                      width={'33%'}
                      src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                    />
                    <Button type="primary" style={{width:'100%'}}>See all</Button>
                  </Card>
                </Col>
                <Col span={12}>
                  <div style={{height:300}}>
                    <Image
                      height={300}
                      src="https://www.youngontop.com/wp-content/uploads/2020/03/WhatsApp-Image-2020-02-17-at-09.58.29.jpeg"
                    />

                    <Card
                        style={{ width: 'auto' }}
                        actions={[
                          <SettingOutlined key="setting" />,
                          <EditOutlined key="edit" />,
                          <EllipsisOutlined key="ellipsis" />,
                        ]}
                      >
                        <Card.Meta
                          avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                          title="Momo Comunity"
                          description="This is the description"
                        />
                    </Card>
                  </div>
                </Col>
                <Col span={6} style={{backgroundColor:'green'}}>
      
                </Col>
              </Row>
              </div>
        </div>
    )
}

export {Home}
