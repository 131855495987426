import React from 'react';
import { Modal, Form, Input, Divider, DatePicker, TimePicker,  Switch, Select, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { city, getProfile, provinsi } from '../../redux/actions';
// import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
// import * as CONST from '../utils/server/const'

const { Option } = Select;


const PhotoProfile = ({ visible, onCreate, onCancel, imageUpload }) => {
  const [form] = Form.useForm();
  
  return (
    <Modal
      visible={visible}
      title="Change Photo Profile"
      okText="Update"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item
          name="file"
        >
          <Input placeholder="Cover file" type="file" onChange={(e) => imageUpload(e.target.files[0])} />
          <p style={{marginTop:10}}>*maksimal file 10mb, disarankan menggunakan rasio 1:1</p>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export {PhotoProfile}