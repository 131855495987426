import { ARTICLES_GET_LOADING, ARTICLES_POST_LOADING, ARTICLES_UPDATE_LOADING, ARTICLE_DELETE_LOADING, ARTICLE_DETAIL_LOADING } from "../const.config";

export const articlesGetWatcher = data =>({
    type: ARTICLES_GET_LOADING,
    payload: data
})

export const articleDetailWatcher = data =>({
    type: ARTICLE_DETAIL_LOADING,
    payload: data
})

export const articleDeleteWatcher = data =>({
    type: ARTICLE_DELETE_LOADING,
    payload: data
})

export const articlePost = data =>({
    type: ARTICLES_POST_LOADING,
    payload: data
})

export const articleUpadte = data =>({
    type: ARTICLES_UPDATE_LOADING,
    payload: data
})