import * as t from '../const.config'

const initialState = {
    data:[],
    action:null,
    loading:false
}

const reducerMember = (state=initialState, action) => {
    switch(action.type){
        case t.MEMBER_GET_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.MEMBER_GET_SUCCSESS:
            return{
                ...state,
                action:action.type,
                data:action.data,
                loading:false
            }
        case t.MEMBER_GET_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        default:
            return state
    }
}

export {reducerMember}