import {takeEvery, call, put} from 'redux-saga/effects'
import * as t from '../const.config'
import Axios from '../../utils/server/fetch'
import qs from 'qs'

export const watcherMember = [
    takeEvery(t.MEMBER_GET_LOADING, workerMemberGet)
]

const getMember = async body => {
    try{
        const data = await Axios({
            url:`api/custom_service/?service=w_getmember`,
            data:qs.stringify({
                ACCESS_TOKEN:body.token,
            })
        })
        return data
    } catch (error) {
        return error
    }
}
function* workerMemberGet(action){
    try{
        const response = yield call(getMember, action.payload)
        if(response.status === 200){
            yield put({
                type:t.MEMBER_GET_SUCCSESS,
                data: response.data.result
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.MEMBER_GET_FAILURE,
            message:error.response.data.message
        })
    }
}