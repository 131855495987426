import React from 'react'
import { Table, Row, Col, Button, message, Input, Divider, Spin, Popover, Modal } from 'antd'
import { MoreOutlined, DeleteOutlined,ExclamationCircleOutlined,FormOutlined } from '@ant-design/icons';

import {Header, Profile} from '../../components'
import { useDispatch, useSelector } from 'react-redux';
import {articleDeleteWatcher, articlesGetWatcher} from '../../redux/actions'
import { useHistory } from 'react-router-dom';
import * as URL from '../../utils/routers/constant'
import * as TYPE from '../../redux/const.config'
import * as LINK from '../../utils/routers/constant'
// import ReactPlayer from 'react-player'

function Articles() {
  const history = useHistory()
  const dispatch = useDispatch()
  const getArticles = useSelector(state => state.articles)
  const user = useSelector(state => state.user)
  
  React.useEffect(()=> {
    let body = {
      search:"",
      token:user.user.access_token_data?.token
    }
    dispatch(articlesGetWatcher(body))
  },[])

  React.useEffect(() => {
    let body = {
      token:user.user.access_token_data?.token,
      search:''
    }
    if (getArticles.action === TYPE.ARTICLE_DELETE_SUCCSESS){
      message.info(getArticles.message)
      dispatch(articlesGetWatcher(body))
    }
  })


  const onSearch = value => {
    let body = {
      search:value,
      token:user.user.access_token_data?.token
    }
    dispatch(articlesGetWatcher(body))
  }
  
  const columns = [
    {
      title: '',
      dataIndex: 'featuredimage',
      render: img => (
        <div>
          <img style={{borderRadius:10}} src={img} width="100" height='100' />
        </div>
      )
    },
    {
      title: '',
      dataIndex: ['post_title', 'id'],
      render: (judul, data) => (
        <Button style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word',margin:0, padding:0, textAlign:'start'}} type="link" onClick={() =>history.push(`${URL.Articles}/${data.id}`)}>
          <p>{data.post_title}</p>
        </Button>
      )
    },
    {
      title: 'Views',
      dataIndex: 'liked_count',
    },
    {
      title: 'Comment',
      dataIndex: 'comment_count',
    },
    {
      title:'Like',
      dataIndex:'liked_count'
    },
    {
      render: data => (
        <Popover placement="left" content={
          <>
          <Button onClick={() => history.push(`${LINK.ArticleNew}/${data.id}`)} style={{display:'flex', alignItems:'center', textAlign:'center'}} type="link">
            <FormOutlined /> Edit
          </Button>
          <Button onClick={() => showDeleteConfirm(data.id)} style={{display:'flex', alignItems:'center', textAlign:'center'}} type="link">
            <DeleteOutlined /> Delete
          </Button>
            </>
          } trigger="click">
            <Button type="link">
              <MoreOutlined />
            </Button>
          </Popover>
      )
    }
  ];
  const showDeleteConfirm = (id) => {
    Modal.confirm({
      title: 'Are you sure delete this task?',
      icon: <ExclamationCircleOutlined />,
      content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        const body = {
          token : user.user.access_token_data?.token,
          id: id
        }
        dispatch(articleDeleteWatcher(body))
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
    return (
        <div>
            <Header />
            <div className="site-layout" style={{ paddingTop: '100px' }}>
              <Row align="top" gutter={[24,16]}>
                <Col span={6}>
                  <Profile />
                </Col>
                <Col span={12}>
                    <div style={{backgroundColor:'white', marginTop:40, borderRadius:10}}>
                        <div style={{ padding:20, display:'flex', justifyContent:"space-between"}}>
                            <Input.Search style={{width:'35%'}} placeholder="search articles" onSearch={onSearch} enterButton />
                            <Button onClick={() => history.push(URL.ArticleNew)} type="primary">New Article</Button>
                        </div>
                        <Divider/>
                        {getArticles.action === TYPE.ARTICLES_GET_SUCCSESS?
                        <Table style={{padding:10}} dataSource={getArticles.data} columns={columns}/>:
                        <div style={{display:'flex', justifyContent:'center', padding:50}}>
                          <Spin />
                        </div>
                        }
                    </div>
                </Col>
              </Row>
              </div>
        </div>
    )
}

export {Articles}
