import React from 'react'
import Helmet from 'react-helmet'

const TitleComponent = ({ title }) => {
    const sufix = " | SocialConnext"
    const defaultTitle = 'SocialConnext';
    return (
        <Helmet>
            <title>{title ? title + sufix : defaultTitle}</title>
        </Helmet>
    );
};

const withTitle = ({ component: Component, title }) => {
    return class Title extends React.Component {
        render() {
            return (
                <React.Fragment>
                    <TitleComponent title={title} />
                    <Component {...this.props} />
                </React.Fragment>
            );
        }
    };
};

export default withTitle