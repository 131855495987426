import React from 'react';
import {Provider} from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import './App.less';
import {store, persistor} from "./redux/store"
import { images } from './res';
import RoutersConfig from './utils/routers';


function App() {
  return (
    <>
    <div className="blockHP" style={{flexDirection:'column', alignItems:'center'}}>
      <img src ={images.logo.default} style={{marginBottom:10}} />
      Saat ini community CMS hanya bisa dibuka melalui desktop
    </div>

    <div className="App">
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <RoutersConfig />
      </PersistGate>
      </Provider>
    </div>
    </>
  );
}

export default App;