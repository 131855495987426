import {takeEvery, call, put} from 'redux-saga/effects'
import * as t from '../const.config'
import Axios from '../../utils/server/fetch'

export const watcherProfile = [
    takeEvery(t.PROFILE_UPDATE_LOADING, profileUpdate),
    takeEvery(t.PROFILE_GET_LOADING, profileGet),
    takeEvery(t.COVER_UPDATE_LOADING, coverUpdate),
    takeEvery(t.PHOTO_PROFILE_UPDATE_LOADING, photoUpdate)
]

const get = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_getprofile`,
            data:body
        })
        return data
    } catch (error) {
        return error
    }
}

const update = async body => {
    try{
        const data = await Axios({
            url:`api/custom_service/?service=w_updateprofile`,
            data:body
        })
        return data
    } catch (error) {
        return error
    }
}

const cover = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_updatecoverimage`,
            data:body
        })
        return data
    } catch (error) {
        return error
    }
}

const photo = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_updateprofileimage`,
            data:body
        })
        return data
    } catch (error) {
        return error
    }
}

function* profileGet(action){
    try{
        const response = yield call(get, action.payload)
        if(response.data.respond === 1){
            yield put({
                type:t.PROFILE_GET_SUCCSESS,
                user: response.data.result[0]
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.PROFILE_GET_FAILURE,
            message:error.data.message
        })
    }
}

function* profileUpdate(action){
    try{
        const response = yield call(update, action.payload)
        if(response.data.respond === 1){
            yield put({
                type:t.PROFILE_UPDATE_SUCCSESS,
                message:response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.PROFILE_UPDATE_FAILURE,
            message:error.data.message
        })
    }
}

function* coverUpdate(action){
    try{
        const response = yield call(cover, action.payload)
        if(response.data.respond === 1){
            yield put({
                type:t.COVER_UPDATE_SUCCSESS,
                message:response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.COVER_UPDATE_FAILURE,
            message:error.data.message
        })
    }
}

function* photoUpdate(action){
    try{
        const response = yield call(photo, action.payload)
        if(response.data.respond === 1){
            yield put({
                type:t.PHOTO_PROFILE_UPDATE_SUCCSESS,
                message:response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.PHOTO_PROFILE_UPDATE_FAILURE,
            message:error.data.message
        })
    }
}