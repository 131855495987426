import * as t from '../const.config'

const initialState = {
    data:[],
    detail:[],
    action:null,
    loading:false
}

const reducerArticles = (state=initialState, action) => {
    switch(action.type){
        case t.ARTICLES_GET_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.ARTICLES_GET_SUCCSESS:
            return{
                ...state,
                action:action.type,
                data:action.data,
                loading:false
            }
        case t.ARTICLES_GET_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.ARTICLE_DETAIL_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.ARTICLE_DETAIL_SUCCSESS:
            return{
                ...state,
                action:action.type,
                detail:action.detail,
                loading:false
            }
        case t.ARTICLE_DETAIL_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
        }
        case t.ARTICLE_DELETE_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.ARTICLE_DELETE_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
        }
        case t.ARTICLES_POST_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.ARTICLES_POST_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
        }
        case t.ARTICLES_UPDATE_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.ARTICLES_UPDATE_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
        }
        default:
            return state
    }
}

export {reducerArticles}