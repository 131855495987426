import * as P from '../../pages'
const { default: withTitle } = require('./withTitle');

export const HOME = withTitle({ component: P.Home, title: 'Momo' });
export const SIGN_IN = withTitle({ component: P.SignIn, title: 'Masuk Akun' });
export const SIGN_UP = withTitle({ component: P.SignUp, title: 'Daftar Akun' });
export const FORGET = withTitle({ component: P.LostPassword, title: 'Lupa Kata Sandi' });
export const FORGET_PWD = withTitle({ component: P.ForgetPwd, title: 'Lupa Kata Sandi' });


export const EVENT = withTitle({component:P.Event, title:'Acara'});
export const EVENTDETAIL = withTitle({component:P.EventDetail, title:'Acara'});
export const EVENT_RSVP = withTitle({component:P.RSVP, title:'RSVP'});
export const ARTICLES = withTitle({component:P.Articles, title:'Artikel'});
export const ARTICLENEW = withTitle({component:P.ArticleNew, title:'New Article'});
export const ARTICLEEDIT = withTitle({component:P.ArticleEdit, title:'Edit Article'});
export const ARTICLEDETAIL = withTitle({component:P.ArticleDetail, title:'Artikel'});
export const PODCAST = withTitle({component:P.Podcast, title:'Podcast'});
export const PHOTOS = withTitle({component:P.Photos, title:'Photos'});
export const MEMBER = withTitle({component:P.Member, title:'Member'});
export const PROFILE = withTitle({component:P.Profile, title:'Setting'})