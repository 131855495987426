import * as t from '../const.config'

const initialState = {
    data:[],
    detail:[],
    action:null,
    loading:false
}

const reducerPodcast = (state=initialState, action) => {
    switch(action.type){
        case t.PODCAST_GET_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.PODCAST_GET_SUCCSESS:
            return{
                ...state,
                action:action.type,
                data:action.data,
                loading:false
            }
        case t.PODCAST_GET_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.PODCAST_UPLOAD_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.PODCAST_UPLOAD_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.PODCAST_UPLOAD_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
        }
        case t.PODCAST_UPDATE_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.PODCAST_UPDATE_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.PODCAST_UPDATE_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
        }
        case t.PODCAST_DELETE_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.PODCAST_DELETE_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
        }
        default:
            return state
    }
}

export {reducerPodcast}