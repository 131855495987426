import React from 'react'
import { 
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as URL from './constant'
import * as PG from '../meta'

const RoutersConfig = () => {
    return(
        <Router>
            <Switch>
                <Route path={URL.ForgetPwd} component={PG.FORGET_PWD}/>
                <Route exact path={URL.Forget} component={PG.FORGET}/>
                {/* <Route path={`${URL.Forget}/?key=:key&login=:email`} component={PG.FORGET}/> */}
                <Route path={URL.SignIn} component={PG.SIGN_IN}/>
                <Route path={URL.SignUp} component={PG.SIGN_UP}/>

                <PrivateRoute path="/">
                    <Route exact path={URL.Home}>
                      <Redirect to={URL.Event}/>
                    </Route>
                    <Route exact path={URL.Event} component={PG.EVENT}/>
                    <Route exact path={`${URL.Event}/:id`} component={PG.EVENTDETAIL}/>
                    <Route path={`${URL.Event}/:id/rsvp`} component={PG.EVENT_RSVP}/>

                    <Route exact path={URL.Articles} component={PG.ARTICLES}/>
                    <Route exact path={URL.ArticleNew} component={PG.ARTICLENEW}/>
                    <Route exact path={`${URL.ArticleNew}/:id`} component={PG.ARTICLEEDIT}/>
                    <Route exact path={`${URL.Articles}/:id`} component={PG.ARTICLEDETAIL}/>
                    <Route path={URL.Podcast} component={PG.PODCAST}/>
                    <Route path={URL.Member} component={PG.MEMBER}/>
                    <Route path={URL.Photo} component={PG.PHOTOS}/>

                    <Route path={URL.Profile} component={PG.PROFILE}/>
                </PrivateRoute>
                
            </Switch>
        </Router>
    )
}

function PrivateRoute({ children, ...rest }) {
  const data = useSelector(state => state.user)
  return (
      <Route
        {...rest}
        render={({ location }) =>
          data.user?.Access_Token ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: URL.SignIn,
                state: { from: location }
              }}
            />
          )
        }
      />
    );
}

export default RoutersConfig