import { IMAGE_GET_LOADING, IMAGE_POST_LOADING, PHOTOS_GET_LOADING, PHOTOS_POSTALBUM_LOADING, PHOTOS_POST_DELETE_LOADING, PHOTOS_POST_UPDATE_LOADING } from "../const.config";

export const albumGetWatcher = data =>({
    type: PHOTOS_GET_LOADING,
    payload: data
})

export const imageGetWatcher = data =>({
    type: IMAGE_GET_LOADING,
    payload: data
})

export const imagePostWatcher = data =>({
    type: IMAGE_POST_LOADING,
    payload: data
})

export const albumNew = data =>({
    type: PHOTOS_POSTALBUM_LOADING,
    payload: data
})

export const albumUpdate = data =>({
    type: PHOTOS_POST_UPDATE_LOADING,
    payload: data
})

export const albumDelete = data =>({
    type: PHOTOS_POST_DELETE_LOADING,
    payload: data
})