import { EVENT_GET_LOADING, EVENT_DETAIL_LOADING, EVENT_POST_LOADING, EVENT_DELETE_LOADING, EVENT_UPDATE_LOADING, RSVP_GET_LOADING, ACC_RSVP_GET_LOADING } from "../const.config";

export const eventGetWatcher = data =>({
    type: EVENT_GET_LOADING,
    payload: data
})

export const getRSVP = data =>({
    type: RSVP_GET_LOADING,
    payload: data
})

export const approveRSVP = data =>({
    type: ACC_RSVP_GET_LOADING,
    payload: data
})

export const eventDetailWatcher = data =>({
    type: EVENT_DETAIL_LOADING,
    payload: data
})

export const eventPostWatcher = data =>({
    type: EVENT_POST_LOADING,
    payload: data
})

export const eventUpdateWatcher = data =>({
    type: EVENT_UPDATE_LOADING,
    payload: data
})

export const eventRemoveWatcher = data =>({
    type: EVENT_DELETE_LOADING,
    payload: data
})

export const clearEvent = () => ({
    type:"EVENT_CLEAR"
})