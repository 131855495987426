import { Button, Col, Divider, Image, Layout, message, Row, Space, Table } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Header } from '../../components'
import { getProfile, photoProfileUpdate, updateCover, updateProfile } from '../../redux/actions'
import { colors, images } from '../../res'
import {EditAccount} from './'
import * as TYPE from '../../redux/const.config'
import { CoverImage } from './ModalCover'
import {CameraOutlined} from '@ant-design/icons'
import { PhotoProfile } from './ModalProfile'

function Profile() {
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [isCover, setIsCover] = React.useState(false);
    const [isProfile, setIsProfile] = React.useState(false);
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const profile = useSelector(state => state.profile.user)
    const data = useSelector(state => state.profile)
    const [imageU, setImageU] = React.useState(null)

    React.useEffect(() => {
        const body = new FormData()
        body.append("ACCESS_TOKEN",user.user.access_token_data?.token)
        dispatch(getProfile(body))
    },[])

    const onCreate = (values) => {
        const body = new FormData()
        body.append("ACCESS_TOKEN",user.user.access_token_data?.token)
        body.append("display_name", values.display_name?values.display_name:"")
        body.append('email', values.email?values.email:"")
        body.append('phone_number', values.phone_number?values.phone_number:"")
        body.append('description', values.description?values.description:"")
        body.append('province', values.province?values.province:"")
        body.append('city', values.city?values.city:"")
        body.append('category', values.category?values.category:"")
        
        dispatch(updateProfile(body))

        setIsModalVisible(false);
    }

    const imageUpload = (img) => {
        setImageU(img)
    }

    const onProfile = (v) => {
        const body = new FormData()
        body.append("ACCESS_TOKEN",user.user.access_token_data?.token)
        body.append("file",imageU)
        dispatch(photoProfileUpdate(body))
        setIsProfile(false)
    }
    const onCover = (v) => {
        const body = new FormData()
        body.append("ACCESS_TOKEN",user.user.access_token_data?.token)
        body.append("file",imageU)
        dispatch(updateCover(body))
        setIsCover(false)
    }
    
    React.useEffect(() => {
        const body = new FormData()
        body.append("ACCESS_TOKEN",user.user.access_token_data?.token)

        if (data.action === TYPE.PROFILE_UPDATE_LOADING||data.action === TYPE.COVER_UPDATE_LOADING||data.action === TYPE.PHOTO_PROFILE_UPDATE_LOADING){
            message.loading({content:"Loading...", key:data.action})
        }else if (data.action === TYPE.PROFILE_UPDATE_SUCCSESS||data.action === TYPE.COVER_UPDATE_SUCCSESS || data.action === TYPE.PHOTO_PROFILE_UPDATE_SUCCSESS){
            message.success(data.message)
            dispatch(getProfile(body))
        } 
    })
    return (
        <div>
            <Header/>
            <div className="site-layout" style={{ paddingTop: '100px'}}>
              <Row align="top" gutter={[24,16]}>
                <Col span={6}>
                  
                </Col>
                <Col span={12} style={{paddingTop:30}}>
                    <div style={{backgroundColor:'white', borderRadius:10}}>
                        <h3 style={{padding:15,fontWeight:'bold'}}>Settings Account</h3>
                    </div>
                    <div style={{backgroundColor:'white', borderRadius:10}}>
                        <div style={{display:'flex', justifyContent:'flex-start', flexDirection:'column'}}>
                        <Image
                            preview={false}
                            style={{borderTopLeftRadius:10, borderTopRightRadius:10, borderBottom:2, borderColor:'red'}}
                            height={300}
                            src={profile.cover_image?profile.cover_image:images.cover.default}
                        />
                        <Button type="default" onClick={() => setIsCover(true)} style={{marginTop:-50, maxWidth:'20%', alignSelf:'flex-end', marginRight:10}}>
                            Edit Cover
                        </Button>
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <div>
                                <Image
                                    preview={false}
                                    width={200}
                                    style={{borderRadius:'50%',marginTop:-100, marginLeft:20, border:`3px solid ${colors.primary}`}}
                                    src={profile.author_image}
                                />
                                <Button type="dashed" onClick={() => setIsProfile(true)} style={{marginLeft:-50, marginTop:-30, borderRadius:'50%', height:50, width:50}}>
                                    <CameraOutlined style={{fontSize:30, alignSelf:'center', display:'flex'}} />
                                </Button>
                            </div>
                            <Button type="primary" onClick={() => setIsModalVisible(true)} style={{marginRight:10, marginTop:30}}>Edit Profile</Button>
                            
                        </div>
                        </div>
                        <div style={{padding:16}}>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:"flex-start"}}>
                                <p style={{width:'100%'}}>Display Name</p>
                                <p style={{width:'100%'}}>{profile.community_name}</p>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:"space-between"}}>
                                <p style={{width:'100%'}}>Email</p>
                                <p  style={{width:'100%'}}>{profile.email}</p>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:"space-between"}}>
                                <p style={{width:'100%'}}>Username</p>
                                <p  style={{width:'100%'}}>{profile.username}</p>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:"space-between"}}>
                                <p style={{width:'100%'}}>About</p>

                                <p  style={{width:'100%'}}>{profile.description}</p>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:"space-between"}}>
                                <p style={{width:'100%'}}>Category</p>
                                <p  style={{width:'100%'}}>{profile.author_category_name}</p>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:"space-between"}}>
                                <p style={{width:'100%'}}>Province</p>
                                <p  style={{width:'100%'}}>{profile.province_name}</p>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:"space-between"}}>
                                <p style={{width:'100%'}}>City</p>
                                <p  style={{width:'100%'}}>{profile.city_name}</p>
                            </div>
                            <div style={{display:'flex', flexDirection:'row', justifyContent:"space-between"}}>
                                <p style={{width:'100%'}}>Member</p>
                                <p  style={{width:'100%'}}>{profile.followers} member</p>
                            </div>
                        </div>
                    </div>
                </Col>
                
              </Row>
              </div>
              <EditAccount
                visible={isModalVisible}
                onCreate={onCreate}
                // imageUpload={imageUpload}
                onCancel={() => {
                    setIsModalVisible(false);
                }}
            />
            <CoverImage
                visible={isCover}
                onCreate={onCover}
                imageUpload={imageUpload}
                onCancel={() => {
                    setIsCover(false);
                }}
            />
            <PhotoProfile
                visible={isProfile}
                onCreate={onProfile}
                imageUpload={imageUpload}
                onCancel={() => {
                    setIsProfile(false);
                }}
            />
        </div>
    )
}

export {Profile}
