
import { all } from 'redux-saga/effects'
import {watcherArticles, watcherAuth, watcherEvent, watcherMember, watcherPhotos, watcherPodcast, watcherList, watcherProfile} from '../sagas'
// import { watcherGroup } from '../sagas/group'

const watchers = [
    ...watcherAuth,
    ...watcherEvent,
    ...watcherArticles,
    ...watcherPodcast,
    ...watcherPhotos,
    ...watcherMember,
    ...watcherList,
    ...watcherProfile
    // ...watcherPayments,
    // ...watcherBuy,
    // ...watcherCheckout,
    // ...watcherGroup
]

export default function* rootSaga() {
    yield all(watchers)
}