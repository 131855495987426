import React from 'react'
import { Card, Row, Col, Button, Image, List, Input, Avatar, Spin, message, Popover, Modal, Empty } from 'antd'
import './index.css'
import { ExclamationCircleOutlined,DeleteOutlined, MoreOutlined,FormOutlined } from '@ant-design/icons';
// import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {Header, Profile} from '../../components'
import {ModalNewAlbum} from './ModalNewAlbum'
import {albumDelete, albumGetWatcher, albumNew, albumUpdate, imageGetWatcher, imagePostWatcher} from '../../redux/actions'
import * as TYPE from '../../redux/const.config'
import { ModalEditAlbum } from './ModalEditAlbum';
import { ModalNewImage } from './ModalNewImage';

function Photos() {
  // const history = useHistory()
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = React.useState(false);
  const [isModalImage, setIsModalImage] = React.useState(false);
  const [album, setAlbum] = React.useState({})
  const [imageU, setImageU] = React.useState(null)
  const dispatch = useDispatch()
  const data = useSelector(state => state.photos)
  const user = useSelector(state => state.user)

  
  React.useEffect(()=> {
    let body = {
      token:user.user.access_token_data?.token,
      id:album.ID
    }
    dispatch(imageGetWatcher(body))
  },[album])

  React.useEffect(()=> {
    let body = {
      token:user.user.access_token_data?.token
    }
    dispatch(albumGetWatcher(body))
  },[])

  React.useEffect(() => {
    if(data.action === TYPE.PHOTOS_POSTALBUM_SUCCSESS) {
      setIsModalVisible(false)
      let body = {
        token:user.user.access_token_data?.token
      }
      dispatch(albumGetWatcher(body))
    } else if (data.action === TYPE.PHOTOS_POSTALBUM_FAILURE){
      message.warn(data.message)
    } else if (data.action === TYPE.PHOTOS_POSTALBUM_LOADING){
      // null
    } else if(data.action === TYPE.PHOTOS_POST_UPDATE_SUCCSESS) {
      setIsModalEditVisible(false)
      let body = {
        token:user.user.access_token_data?.token
      }
      dispatch(albumGetWatcher(body))
    } else if (data.action === TYPE.PHOTOS_POST_UPDATE_FAILURE){
      message.warn(data.message)
    } else if(data.action === TYPE.PHOTOS_POST_DELETE_SUCCSESS) {
      setIsModalEditVisible(false)
      message.info(data.message)
      let body = {
        token:user.user.access_token_data?.token
      }
      dispatch(albumGetWatcher(body))
    } else if(data.action === TYPE.IMAGE_POST_SUCCSESS) {
      setIsModalImage(false)
      let body = {
        token:user.user.access_token_data?.token,
        id:album.ID
      }
      dispatch(imageGetWatcher(body))
    }
  })

  const albumSelect = (id) => {
    setAlbum(id)
  }

  const onCreate = (values) => {
    let body = {
      token:user.user.access_token_data?.token,
      subject:values.subject,
      content:values.content
    }
    dispatch(albumNew(body))
  }

  const onEdit = (values) => {
    let body = {
      token:user.user.access_token_data?.token,
      subject:values.subject,
      id:album.ID,
      content:values.content
    }
    dispatch(albumUpdate(body))
  }

  const newImageUpload = (values) => {
    let data = new FormData()
    data.append('ACCESS_TOKEN', user.user.access_token_data?.token)
    data.append('id', album.ID)
    data.append('image', imageU)
    console.log(data, "ini adalah datanya")
    dispatch(imagePostWatcher(data))
  }

  const showDeleteConfirm = (id) => {
    Modal.confirm({
      title: 'Are you sure delete this task?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        const body = {
          token : user.user.access_token_data?.token,
          id: id.ID
        }
        // console.log(body, "ketriger")
        dispatch(albumDelete(body))
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  return (
      <div>
          <Header />
          <div className="site-layout" style={{ paddingTop: '100px' }}>
            <Row align="top" gutter={[24,16]}>
              <Col span={6}>
                <Profile />
              </Col>
              <Col span={12}>
                <div style={{backgroundColor:'white', width:'100%', padding:10, marginTop:40, borderRadius:10}}>
                <div style={{ padding:20, display:'flex', justifyContent:"space-between"}}>
                    <h3 style={{fontWeight:'bold'}}>Albums</h3>
                    <Button onClick={() => setIsModalVisible(true)} type="primary">Add Album</Button>
                </div>
                <div>

                </div>
                <div style={{width:'100%', display:'flex'}}>
                  {data.data?.map((item, index) => 
                    // console.log(item.album.ID, "ini apa"),
                    <button onClick={() => albumSelect(item.album)} style={{backgroundColor:'transparent', borderColor:'transparent', padding:0, margin:0}}>
                  <Card
                    // hoverable
                    style={{ width: 240, padding:10 }}
                    cover={<img alt="example" style={{width:'100%', height:200}} src={item.album.cover?item.album.cover:"https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"} />}
                  >
                    <Card.Meta  style={{paddingTop:10}} title={item.album.post_title.substr(0,25)} description={item.album.post_content.substr(0,15)} />
                  </Card>
                  </button>
                  )}
                </div>
                </div>
                <div style={{backgroundColor:'white', marginTop:40, borderRadius:10}}>
                  <div style={{ padding:20, display:'flex', justifyContent:"space-between"}}>
                    <h3 style={{fontWeight:'bold'}}>Photos</h3>
                    <div>
                      <Button disabled={!album.ID } onClick={() => setIsModalImage(true)} type="primary">New Image</Button>
                      {album.ID?
                      <Popover placement="bottomRight" content={
                        <>
                        <Button onClick={() => setIsModalEditVisible(true)} style={{display:'flex', alignItems:'center', textAlign:'center'}} type="link">
                          <FormOutlined /> Edit
                        </Button>
                        <Button onClick={() => showDeleteConfirm(album)} style={{display:'flex', alignItems:'center', textAlign:'center'}} type="link">
                          <DeleteOutlined /> Delete
                        </Button>
                        </>
                      } trigger="click">
                        <Button type="link">
                          <MoreOutlined />
                        </Button>
                      </Popover>:null}
                    </div>
                  </div>
                  {data.action === TYPE.IMAGE_GET_SUCCSESS?<List
                    style={{padding:10}}
                    grid={{ gutter: 16, column: 4 }}
                    dataSource={data.image}
                    renderItem={item => (
                      <List.Item>
                        <Card style={{margin:0, padding:0, borderWidth:0}}>
                          <Image
                            style={{borderRadius:10}}
                            width="auto"
                            height={200}
                            src={item.full_image_url}
                          />
                        </Card>
                      </List.Item>
                    )}
                  />:<div style={{display:'flex', justifyContent:'center', padding:50}}>
                  {/* <Spin /> */}
                  <Empty 
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                      <span>
                        Please select an album
                      </span>
                    }
                  />
                </div>}
                </div>
                  
              </Col>
            </Row>
            </div>
          <ModalNewAlbum
            visible={isModalVisible}
            onCreate={onCreate}
            onCancel={() => {
              setIsModalVisible(false);
            }}
          />
          <ModalEditAlbum
            visible={isModalEditVisible}
            onCreate={onEdit}
            title={album.post_title}
            desc={album.post_content}
            onCancel={() => {
              setIsModalEditVisible(false);
            }}
          />
          <ModalNewImage
            visible={isModalImage}
            onCreate={newImageUpload}
            imageUpload={(item) => setImageU(item)}
            onCancel={() => {
              setIsModalImage(false);
            }}
          />
      </div>
  )
}

export {Photos}
