import React from 'react'
import {Row, Col, Form, Input, Button, message} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import {images} from '../../res'
import * as URL from '../../utils/routers/constant'
import { Link, useHistory } from 'react-router-dom'
import {signInWatcher} from '../../redux/actions'
import * as t from '../../redux/const.config'

function SignIn() {
    const dispatch = useDispatch()
    const data = useSelector(state => state.user)
    const history = useHistory()
    
    React.useEffect(() => {
        if(data.action === t.SIGN_IN_SUCCSESS){
            history.push(URL.Event)
        } else if (data.action === t.SIGN_IN_FAILURE){
            message.error(data.message)
        }
    })
    const onFinish = (values) => {
        dispatch(signInWatcher(values))
    //   console.log(values);
    };
    return (
        <Row gutter={[16, 16]} style={{height:'100vh', padding:100, marginRight:100, marginLeft:100}}>
            <Col span={12} offset={4}>
                <div style={{padding:8}}>
                    <img alt="logo" src={images.logo.default}/>
                    <h2 style={{marginTop:20}}>
                    Connect all communities for stronger nation
                    </h2>
                    <div style={{padding:20}}>
                    <a href="https://play.google.com/store/apps/details?id=com.socialconnext&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                        <img src={images.download.android.default} style={{marginRight:25}}/>
                        </a>
                        {/* <img src={images.download.ios.default}/> */}
                    </div>
                </div>
            </Col>
            <Col span={8}>
            <Form
                name="normal_login"
                size="large"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <div
                    style={{backgroundColor:'white', padding:20, borderRadius:10}}
                >
                    <div>
                        <h1 style={{margin:0}}>
                            Log In
                        </h1>
                        <p>
                        Manage your community professionally
                        </p>
                    </div>
                    <Form.Item
                        name="username"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your Username!',
                        },
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email or Username" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your Password!',
                        },
                        ]}
                    >
                        <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Link to={URL.ForgetPwd}>
                            <p>Forgotten Password?</p>
                        </Link>
                        {/* <a className="login-form-forgot" href="">
                        
                        </a> */}
                    </Form.Item>

                    <Form.Item>
                        <Button loading={data.action === t.SIGN_IN_LOADING} style={{justifyContent:'center', width:'100%'}} type="primary" htmlType="submit" className="login-form-button">
                        Log In
                        </Button>
                    </Form.Item>         
                </div>
                <div style={{marginTop:12, textAlign:'center'}}>
                Create Page for your Community,  
                    <Link to={URL.SignUp}> Sign Up Now</Link>
                </div>
            </Form>
            </Col>
        </Row>
    );
}

export {SignIn}
