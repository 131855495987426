import { Button, Form, Input, message, Row } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { forgetPassword } from '../../redux/actions/auth';
import * as t from '../../redux/const.config'
import * as URL from '../../utils/routers/constant'

function ForgetPwd() {
    const dispatch = useDispatch()
    const data = useSelector(state=>state.user)
    const history = useHistory()

    React.useEffect(() => {
        if(data.action === t.FORGET_PASSWORD_SUCCSESS){
            message.success(data.message)
        }
    })
    const onFinish = (values) => {
        dispatch(forgetPassword(values.email))
    }
    return (
        <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
            <Form
                style={{display:'block'}}
                name="normal_login"
                size="large"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <div
                    style={{backgroundColor:'white', padding:20, borderRadius:10}}
                >
                    <div>
                        <h1 style={{margin:0}}>
                        Find Your Account
                        </h1>
                        <p>
                        Please enter your email address to reset for your password.
                        </p>
                    </div>
                    <Form.Item
                        name="email"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                        },
                        ]}
                    >
                        <Input 
                            placeholder="Email" />
                    </Form.Item>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
                        <Form.Item style={{marginRight:10}}>
                            <Button type="default" onClick={() => history.push(URL.SignIn)}>
                                Cancle
                            </Button>
                        </Form.Item> 

                        <Form.Item>
                            <Button  style={{justifyContent:'center', width:'100%'}} type="primary" htmlType="submit" className="login-form-button">
                                Reset
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </Row>
    )
}

export {ForgetPwd}
