import React from 'react';
import { Modal, Form, Input, Divider, DatePicker, TimePicker,  Switch } from 'antd';
// import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
// import * as CONST from '../utils/server/const'

const CollectionCreateForm = ({ visible, onCreate, onCancel, imageUpload }) => {
  const [form] = Form.useForm();
  const [image, setImage] = React.useState({ preview: "", raw: "" });
  const [free, setFree] = React.useState(false)

  const handleChange = e => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
      imageUpload(e.target.files[0])
    }
  }

  function onChange(checked) {
    setFree(checked)
  }
  
  return (
    <Modal
      visible={visible}
      title="New Event"
      okText="Post"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item
          name="subject"
          rules={[
            {
              required: true,
              message: 'Please input the title of Event!',
            },
          ]}
        >
          <Input placeholder="Title" />
        </Form.Item>
        <Form.Item
          name="content"
          rules={[
            {
              required: true,
              message: 'Please input description of Event!',
            },
          ]}
        >
          <Input.TextArea placeholder="Description" type="textarea" />
        </Form.Item>
        <Form.Item
         name="lokasi"
         rules={[
          {
            required: true,
            message: 'Please input location of Event!',
          },
        ]}
        >
            <Input placeholder="Location"/>
        </Form.Item>
        <Divider />
        <div style={{display:'flex'}}>
          <Form.Item 
            style={{marginRight:10}} 
            name="tanggal_event"
            rules={[
              {
                required: true,
                message: 'Please input date of Event!',
              },
            ]}
          >
            <DatePicker placeholder="Tanggal" />
          </Form.Item>
          <Form.Item
            name="waktu_mulai"
            rules={[
              {
                required: true,
                message: 'Please input time of Event!',
              },
            ]}
          >
            <TimePicker format="HH:mm" placeholder="Start" />
          </Form.Item>
        </div>
        {/* <Form.Item name="tanggal_event" {...rangeConfig}>
          <DatePicker.RangePicker showTime format="YYYY-MM-DD HH:mm:ss" onChange={onchange} />
        </Form.Item> */}

        <Form.Item>
          <Switch onChange={onChange} /> Free Event
        </Form.Item>
        
        <Form.Item name="harga">
            <Input disabled={free} placeholder="Price"/>
        </Form.Item>
        <Form.Item name="payment_info">
            <Input disabled={free} placeholder="Nama Bank - No Rekening"/>
        </Form.Item>
        <Form.Item name="payment_pic">
            <Input disabled={free} placeholder="Nama Rekening"/>
        </Form.Item>
        <p>Thumbail</p>
        <Form.Item name="file">
          <Input type="file" onChange={handleChange}/>
        </Form.Item>
        {/* <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {img ? <img src={img} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload> */}
      </Form>
    </Modal>
  );
};

export {CollectionCreateForm}