import React from 'react'
import { Row, Col, Button, Image, Calendar,Avatar, Tabs, List, Divider, Skeleton } from 'antd'
import parse from "html-react-parser"
import {Header, Profile} from '../../components'
import { colors } from '../../res';
import { useDispatch, useSelector } from 'react-redux';
import {articleDetailWatcher, eventDetailWatcher} from '../../redux/actions'
import { useParams } from 'react-router-dom';
import * as TYPE from '../../redux/const.config';

function ArticleDetail() {
  // const [data,setData] = React.useState({})
  const dispatch = useDispatch()
  const _data = useSelector(state => state.articles)
  const user = useSelector(state => state.user)
  const {id} = useParams()

  React.useEffect(()=> {
    let body = {
      token:user.user.access_token_data?.token,
      post_id:id
    }
    dispatch(articleDetailWatcher(body))
    // setData(getEvent[0])
  },[])

  const data = _data.detail[0]
      
    return (
        <div>
            <Header />
            <div className="site-layout" style={{ paddingTop: '100px' }}>
              <Row align="top" gutter={[24,16]}>
                <Col span={6}>
                  <Profile />
                </Col>
            {/* {_data.action === TYPE.ARTICLE_DETAIL_SUCCSESS? */}
                <Col span={12} style={{marginTop:20, backgroundColor:'white', borderRadius:10}}>
                {_data.action === TYPE.ARTICLE_DETAIL_SUCCSESS?
                <>
                  <h2>{data.category.map(data => {
                    return data.name
                  })}</h2>
                  <h1>{data.post_title}</h1>
                  <p>{data.post_date}</p>
                    <img width="100%" style={{borderRadius:10,marginTop:20}} src={data?.featuredimage?data?.featuredimage:null}/>
                    <div style={{marginTop:20}}>
                      {parse(data.post_content)}
                    </div>
                    <Divider />
                  </>:
                  <Skeleton active />}
                </Col>
                {/* :<h1>Loading</h1>} */}
              </Row>
              </div>
        </div>
    )
}

export {ArticleDetail}
