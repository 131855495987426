import React from 'react'
import { Row, Col, Button, Image, Calendar,Avatar, Tabs, List, Divider } from 'antd'
import { ClockCircleOutlined,EnvironmentOutlined,DollarCircleOutlined } from '@ant-design/icons'
import {Header, Profile} from '../../components'
import { colors } from '../../res';
import { useDispatch, useSelector } from 'react-redux';
import {eventDetailWatcher, clearEvent} from '../../redux/actions'
import { useParams } from 'react-router-dom';
import * as TYPE from '../../redux/const.config'

function EventDetail() {
  const [data,setData] = React.useState({})
  const dispatch = useDispatch()
  const getEvent = useSelector(state => state.events)
  const user = useSelector(state => state.user)
  const {id} = useParams()

  React.useEffect(()=> {
    // dispatch(clearEvent())
    // console.log(getEvent.detail, "2")
    let body = {
      token:user.user.access_token_data?.token,
      data_id:id
    }
    dispatch(eventDetailWatcher(body))
    setData(getEvent.detail[0])
  },[])
      
    return (
        <div>
          {console.log(getEvent.detail[0], "ini detail")}
            <Header />
            <div className="site-layout" style={{ paddingTop: '100px' }}>
              <Row align="top" gutter={[24,16]}>
                <Col span={6}>
                  <Profile />
                </Col>
                <Col span={12} style={{backgroundColor:'white'}}>
                  {getEvent.action === TYPE.EVENT_DETAIL_SUCCSESS?
                  <>
                        <img width="100%" style={{borderRadius:10,marginTop:20}} src={getEvent.detail[0]?.featuredimage[0].image}/>
                    <div style={{marginTop:20}}>
                      <h2>Detail</h2>
                      <div style={{display:'flex', alignItems:'center', textAlign:'center'}}>
                        <ClockCircleOutlined style={{marginRight:5}} />
                        {getEvent.detail[0]?.waktu_mulai}
                      </div>
                      <div style={{display:'flex', alignItems:'center', textAlign:'center'}}>
                        <EnvironmentOutlined style={{marginRight:5}} />
                        {getEvent.detail[0]?.lokasi}
                      </div>
                      <div style={{display:'flex', alignItems:'center', textAlign:'center'}}>
                        <DollarCircleOutlined style={{marginRight:5}} />
                        Rp  {getEvent.detail[0]?.ticket_price === null?'0':getEvent.detail[0]?.ticket_price}
                      </div>

                      <div style={{marginTop:10}}>
                        <h2>{getEvent.detail[0]?.post_title}</h2>
                        {getEvent.detail[0]?.post_content}
                      </div>

                      <div style={{display:'flex', justifyContent:'flex-end'}}>
                        <p>{getEvent.detail[0]?.liked_count} likes | {getEvent.detail[0]?.comment_count} coments</p>
                      </div>
                    </div>
                    </>:null}
                    <Divider />
                    
                </Col>
                
              </Row>
              </div>
        </div>
    )
}

export {EventDetail}
