import React from 'react'
import { Input, Avatar, Menu, Popover, Button, Modal, Badge, Empty, notification } from 'antd'
import { BellOutlined, MessageOutlined,SettingOutlined,CrownOutlined,LogoutOutlined } from '@ant-design/icons';
import {images, colors} from '../res'
import * as LINK  from '../utils/routers/constant'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as URL from '../utils/routers/constant'
import { signOutWatcher } from '../redux/actions/auth';

const { Search } = Input;

function Header() {
  const history =useHistory()
  const dispatch = useDispatch()
  const profile = useSelector(state => state.profile.user)
  const pathname = window.location.pathname

  const openNotification = () => {
    notification.open({
      message: 'Available Soon',
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
  };

  const content = (
    <div>
      <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:15}}>
        <Avatar size="large" src={profile.author_image} />
        <h3 style={{marginLeft:5}}>{profile.community_name}</h3>
      </div>
        <Button onClick={() => history.push(LINK.Profile)} type="text" style={style.menu}>
          <SettingOutlined />
          Settings
        </Button>
        {/* <Button type="text" style={style.menu}>
          <CrownOutlined />
          Premium
        </Button> */}
        <Button type="link" onClick={showDeleteConfirm} style={style.menu}>
          <LogoutOutlined />
          Sign Out
        </Button>
    </div>
  );

  const notif = (
    <div style={{paddingLeft:20, paddingRight:20}}>
      <h3>Notifications</h3>
      <div style={{padding:10}}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    </div>
  );
  const onSearch = value => console.log(value);

  function showDeleteConfirm() {
    Modal.confirm({
      title: 'Apakah kamu yakin mau keluar?',
      icon: <LogoutOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(signOutWatcher())
        console.log('OK');
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  return (
      <div>
          <div style={{position: 'fixed', zIndex: 7, width: '100%', backgroundColor:'yellow' }}>
            <div style={{height:52, backgroundColor:'#D0161A', alignItems:'center', display:'flex', padding:16, justifyContent:'space-between'}}>
              <div>
                <a href={LINK.Home}>
                  <img src={images.logo_white.default} alt="logo" />
                </a>
                  {/* <Search placeholder="input search text" allowClear onSearch={onSearch} style={{ width: 200, marginLeft:20, borderRadius:50, backgroundColor:'#FA9C9E' }} /> */}
              </div>
              <div style={{display:'flex', alignItems:'center'}}>
                  <Popover placement="bottom" content={notif} trigger="click">
                    {/* <Badge style={{backgroundColor:'white', color:colors.primary}} size="small" count={5}> */}
                      <BellOutlined style={{fontSize:24, color:'white'}} />
                    {/* </Badge> */}
                  </Popover>
                  <div style={{fontSize:24, paddingLeft:15, paddingRight:15, color:'white'}}/>
                  <Popover placement="bottomRight" content={content} trigger="click">
                    <Avatar src={profile.author_image} />
                  </Popover>
              </div>
            </div>
            <div style={{height:52,backgroundColor:colors.primary, justifyContent:'center', display:'flex'}}>
              <Menu selectedKeys={pathname} theme="dark" style={{backgroundColor:colors.primary}} mode="horizontal">
                <Menu.Item key={URL.Event} onClick={() => history.push(LINK.Event)}>
                  Event
                </Menu.Item>
                <Menu.Item key={URL.Articles} onClick={() => history.push(LINK.Articles)}>
                  Articles
                </Menu.Item>
                <Menu.Item key={URL.Photo} onClick={() => history.push(LINK.Photo)}>
                  Photos
                </Menu.Item>
                <Menu.Item key={URL.Member} onClick={() => history.push(LINK.Member)} >
                  Members
                </Menu.Item>
                <Menu.Item key={URL.Podcast} onClick={() => history.push(LINK.Podcast)} >
                  Podcast
                </Menu.Item>
                <Menu.Item key={URL.Store} onClick={openNotification}>
                  Store
                </Menu.Item>
              </Menu>
            </div>
          </div>
      </div>
  )
}

const style = {
  menu:{
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'center',
    marginTop:5
  }
}

export {Header}
