import { Image } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getProfile } from '../redux/actions'
import {colors} from '../res'
import * as URL from '../utils/routers/constant'

function Profile() {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const profile = useSelector(state => state.profile.user)

    React.useEffect(() => {
        const body = new FormData()
        body.append("ACCESS_TOKEN",user.user.access_token_data?.token)
        dispatch(getProfile(body))
    },[])
    
    return (
        <div 
          style={{ width: 'auto', backgroundColor:colors.bgColor, height:'auto', margin:30 }}
        >
        {/* {console.log(profile, "ini data")} */}
          <div style={{height:132, display:'flex', justifyContent:'center'}}>
              <Image
                  preview={false}
                  width={200}
                  style={{borderRadius:'50%'}}
                  src={profile.author_image}
              />
          </div>
          <div style={{padding:'100px 20px 20px', backgroundColor:'white', borderRadius:15}}>
              <div style={{ width:'100%', textAlign:'center'}}>
                  <h2>
                      {profile.community_name}
                  </h2>
                  <Link to={URL.Member}>
                    <h4>
                        {profile.followers} members
                    </h4>
                  </Link>
              </div>
              <h3 style={{paddingTop:10, fontWeight:'bold'}}>About</h3>
              <p>
                  {profile.description}
              {/* Group untuk komunitas Young On Top yang berada di wilayah Daerah Jakarta. Follow Young On Top jakarta untuk mendapatkan informasi... See More */}
              </p>
              {/* <h3 style={{paddingTop:10, fontWeight:'bold'}}>Indonesia - Jakarta</h3> */}
          </div>
        </div>
    )
}

export {Profile}
