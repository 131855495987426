import { Header } from '../../components'
import React from 'react'
import CKEditor from "react-ckeditor-component"
import { Button, Col, Row, Form, Input, message } from 'antd';
import { Profile } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { articleDetailWatcher, articleUpadte } from '../../redux/actions/articles';
import * as TYPE from '../../redux/const.config'
import * as URL from '../../utils/routers/constant'
import { useHistory, useParams } from 'react-router-dom';

let config = {
    toolbarGroups: [
      { name: "document", groups: ["mode", "document", "doctools"] },
      {
        name: "editing",
        groups: ["find", "selection", "spellchecker", "editing"]
      },
      { name: "forms", groups: ["forms"] },
      { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
      {
        name: "paragraph",
        groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
      },
      "/",
      { name: "links", groups: ["links"] },
      { name: "insert", groups: ["insert"] },
      { name: "styles", groups: ["styles"] },
      { name: "colors", groups: ["colors"] },
      { name: "tools", groups: ["tools"] },
      "/",
      { name: "clipboard", groups: ["clipboard", "undo"] },
      { name: "others", groups: ["others"] },
      { name: "about", groups: ["about"] }
    ],
    removeButtons:
      "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo",
    fontSize_sizes: "16/16px;24/24px;48/48px;",
    font_names:
      "Arial/Arial, Helvetica, sans-serif;" +
      "Times New Roman/Times New Roman, Times, serif;" +
      "Verdana",
    allowedContent: true
    // disableNativeSpellChecker: false
    // skin: "moono",
    // plugins:
    //   "dialogui,dialog,about,a11yhelp,dialogadvtab,basicstyles,bidi,blockquote,notification,button,toolbar,clipboard,panelbutton,panel,floatpanel,colorbutton,colordialog,templates,menu,contextmenu,copyformatting,div,resize,elementspath,enterkey,entities,popup,filetools,filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo,font,forms,format,horizontalrule,htmlwriter,iframe,wysiwygarea,image,indent,indentblock,indentlist,smiley,justify,menubutton,language,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastetext,pastefromword,preview,print,removeformat,save,selectall,showblocks,showborders,sourcearea,specialchar,scayt,stylescombo,tab,table,tabletools,tableselection,undo,lineutils,widgetselection,widget,notificationaggregator,uploadwidget,uploadimage,wsc"
  };

function ArticleEdit() {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const _data = useSelector(state => state.articles)
    const history = useHistory()
    const [state, setState] = React.useState(null)
    const [imageUpload, setImageUpload] = React.useState(null)
    const {id} = useParams()

    React.useEffect(() => {
      if(_data.action === TYPE.ARTICLES_UPDATE_SUCCSESS){
        message.info(_data.message)
        history.push(URL.Articles)
      }
    })

    React.useEffect(()=> {
      let body = {
        token:user.user.access_token_data?.token,
        post_id:id
      }
      dispatch(articleDetailWatcher(body))
      setState({html:_data.detail[0].post_content})
    },[])
    
    const onChange = (evt) => {
        var html = evt.editor.getData();
        setState({ html });
    }

    const handleChange = e => {
      if (e.target.files.length) {
        setImageUpload(e.target.files[0])
      }
    }

    const onBlur = (evt) => {
      // console.log("onBlur event called with event info: ", evt);
    }

    const afterPaste = (evt) => {
      // console.log("afterPaste event called with event info: ", evt);
    }

  
    const onFinish = (values) => {
      let body = new FormData()
      body.append("ACCESS_TOKEN",user.user.access_token_data?.token)
      body.append("subject", values.subject)
      body.append("content", state.html)
      body.append("file", imageUpload)
      body.append("id", id)
      dispatch(articleUpadte(body))
  };
    return (
        <div>
            <Header />
            <div style={{paddingTop:120}}>
            <Row align="top" gutter={[24,16]}>
                <Col span={6}>
                  <Profile />
                </Col>
                
                <Col span={12} style={{marginTop:20, backgroundColor:'white'}}>  
                  <Form
                      name="normal_login"
                      size="large"
                      className="login-form"
                      initialValues={{
                          remember: true,
                      }}
                      onFinish={onFinish}
                  >
                    <Form.Item name="subject">
                        <Input placeholder="Title"  defaultValue={_data.detail[0].post_title.toString()}/>
                    </Form.Item>
                      <CKEditor
                          activeClass="p10"
                          config={config}
                          content={state?.html}
                          events={{
                              blur: onBlur,
                              afterPaste: afterPaste,
                              change: onChange
                          }}
                      />
                      <Form.Item style={{marginTop:10}} name="file">
                        <h3>Thumbnail</h3>
                        <Input type="file" onChange={handleChange}/>
                      </Form.Item>
                      <Form.Item>
                          <Button style={{justifyContent:'center', width:'100%', marginTop:10}} type="primary" htmlType="submit" className="login-form-button">
                          Update
                          </Button>
                      </Form.Item> 
                  </Form>
                </Col>
                
              </Row>
            </div>
        </div>
    )
}

export {ArticleEdit}
