import { Button, Form, Input, message, Row } from 'antd'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { newPassword, validatePassword } from '../../redux/actions';
import * as t from '../../redux/const.config'
import * as URL from '../../utils/routers/constant'

function LostPassword() {
    const history = useHistory()
    let search = useLocation().search
    const data = useSelector(state => state.user)
    const dispatch = useDispatch()

    const keyOTP = new URLSearchParams(search).get("key")
    const login = new URLSearchParams(search).get("login")

    React.useEffect(() => {
        const body = {
            login:login,
            key:keyOTP

        }
        dispatch(validatePassword(body))
    },[])

    React.useEffect(() => {
        if(data.action === t.NEW_PASSWORD_SUCCSESS){
            message.success(data.message)
            history.push(URL.SignIn)
        }
    })
    
    const onFinish = (values) => {
        let body = {
            password:values.password,
            confirm:values.confirm,
            id:data.user.id
        }
        dispatch(newPassword(body))
    };

    return (
        <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
            <Form
                style={{display:'block', minWidth:'20%'}}
                name="normal_login"
                size="large"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <div
                    style={{backgroundColor:'white', padding:20, borderRadius:10}}
                >
                    <div>
                        <h1 style={{margin:0}}>
                        Create New Password
                        </h1>
                        <p>
                        Please enter your new password.
                        </p>
                    </div>
                    <Form.Item
                        name="password"
                        rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                        {
                            pattern:/^.{8,}$/,
                            message: 'Minimal password 6 characters!',
                        }
                        ]}
                        hasFeedback
                    >
                        <Input.Password 
                            // prefix={<LockOutlined className="site-form-item-icon" />} 
                            placeholder="New Password"
                        />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            {
                                pattern:/^.{8,}$/,
                                message: 'Minimal password 6 characters!',
                            },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }

                            return Promise.reject('The two passwords that you entered do not match!');
                            },
                        }),
                        ]}
                    >
                        <Input.Password 
                            // prefix={<LockOutlined className="site-form-item-icon" />} 
                            placeholder="Confirm Password"
                        />
                    </Form.Item>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
                        <Form.Item style={{marginRight:10}}>
                            <Button onClick={() =>history.push(URL.SignIn)} type="default">
                                Cancle
                            </Button>
                        </Form.Item> 

                        <Form.Item>
                            <Button  style={{justifyContent:'center', width:'100%'}} type="primary" htmlType="submit" className="login-form-button">
                                Reset
                            </Button>
                        </Form.Item>         
                    </div>
                </div>
            </Form>
        </Row>
    )
}

export {LostPassword}
