import {takeEvery, call, put} from 'redux-saga/effects'
import Axios from '../../utils/server/fetch'
import * as t from '../const.config'
import qs from 'qs'

export const watcherList = [
    takeEvery(t.CATEGORY_IN_LOADING, categoties),
    takeEvery(t.PROVINCE_IN_LOADING, province),
    takeEvery(t.CITY_IN_LOADING, cities)
]

const category = async () => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_getcategories`,
            data:qs.stringify({
                CONSUMER_KEY:'119687763',
                SECRET_KEY:'FAACB79A0FEE6E85DE987C10D3559E78A6490BFC122DA4FAD5D2E7F63F08EFA6',
                slug:"communities"
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const provinsi = async () => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=g_getprovince`,
            data:qs.stringify({
                CONSUMER_KEY:'119687763',
                SECRET_KEY:'FAACB79A0FEE6E85DE987C10D3559E78A6490BFC122DA4FAD5D2E7F63F08EFA6'
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const city = async (params) => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=g_getcities&id_prov=${params}`,
            data:qs.stringify({
                CONSUMER_KEY:'119687763',
                SECRET_KEY:'FAACB79A0FEE6E85DE987C10D3559E78A6490BFC122DA4FAD5D2E7F63F08EFA6'
            })
        })
        return data
    } catch (error) {
        return error
    }
}

function* categoties(action){
    try{
        const response = yield call(category, action.payload)
        if(response.data.respond === 1){
            yield put({
                type:t.CATEGORY_IN_SUCCSESS,
                data: response.data.result
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.CATEGORY_IN_FAILURE,
            message:error.data.message
        })
    }
}

function* province(action){
    try{
        const response = yield call(provinsi, action.payload)
        if(response.data.respond === 1){
            yield put({
                type:t.PROVINCE_IN_SUCCSESS,
                province: response.data.result
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.PROVINCE_IN_FAILURE,
            message:error.data.message
        })
    }
}

function* cities(action){
    try{
        const response = yield call(city, action.payload)
        console.log(response, "ini datanya")
        if(response.data.respond === 1){
            yield put({
                type:t.CITY_IN_SUCCSESS,
                city: response.data.result
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.CITY_IN_FAILURE,
            message:error.data.message
        })
    }
}