import React from 'react';
import { Modal, Form, Input, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined,InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const ModalNewAlbum = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [img, setImg] = React.useState(null)
  const [loading,setLoading] = React.useState(false)

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  
  return (
    <Modal
      visible={visible}
      title="New Album"
      okText="Post"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item
          name="subject"
          rules={[
            {
              required: true,
              message: 'Please input the title of Album!',
            },
          ]}
        >
          <Input placeholder="Album" />
        </Form.Item>
        <Form.Item name="content" >
          <Input.TextArea placeholder="Description" type="textarea" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export {ModalNewAlbum}