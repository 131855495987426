import { COVER_UPDATE_LOADING, PHOTO_PROFILE_UPDATE_LOADING, PROFILE_GET_LOADING, PROFILE_UPDATE_LOADING } from "../const.config";

export const getProfile = data =>({
    type: PROFILE_GET_LOADING,
    payload: data
})

export const updateProfile = data =>({
    type: PROFILE_UPDATE_LOADING,
    payload: data
})

export const updateCover = data =>({
    type: COVER_UPDATE_LOADING,
    payload: data
})

export const photoProfileUpdate = data =>({
    type: PHOTO_PROFILE_UPDATE_LOADING,
    payload: data
})