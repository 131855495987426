export const CLEAR_DATA = 'CLEAR_DATA'

export const CATEGORY_IN_LOADING = 'CATEGORY_IN_LOADING'
export const CATEGORY_IN_SUCCSESS = 'CATEGORY_IN_SUCCSESS'
export const CATEGORY_IN_FAILURE = 'CATEGORY_IN_FAILURE'

export const PROVINCE_IN_LOADING = 'PROVINCE_IN_LOADING'
export const PROVINCE_IN_SUCCSESS = 'PROVINCE_IN_SUCCSESS'
export const PROVINCE_IN_FAILURE = 'PROVINCE_IN_FAILURE'

export const CITY_IN_LOADING = 'CITY_IN_LOADING'
export const CITY_IN_SUCCSESS = 'CITY_IN_SUCCSESS'
export const CITY_IN_FAILURE = 'CITY_IN_FAILURE'

// Auth type const
export const SIGN_IN_LOADING = 'SIGN_IN_LOADING'
export const SIGN_IN_SUCCSESS = 'SIGN_IN_SUCCSESS'
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE'

export const FORGET_PASSWORD_LOADING = 'FORGET_PASSWORD_LOADING'
export const FORGET_PASSWORD_SUCCSESS = 'FORGET_PASSWORD_SUCCSESS'
export const FORGET_PASSWORD_FAILURE = 'FORGET_PASSWORD_FAILURE'

export const SIGN_UP_LOADING = 'SIGN_UP_LOADING'
export const SIGN_UP_SUCCSESS = 'SIGN_UP_SUCCSESS'
export const SIGN_UP_FAILURE = 'SIGN_UP_FAILURE'

export const VALIDATE_PASSWORD_LOADING = 'VALIDATE_PASSWORD_LOADING'
export const VALIDATE_PASSWORD_SUCCSESS = 'VALIDATE_PASSWORD_SUCCSESS'
export const VALIDATE_PASSWORD_FAILURE = 'VALIDATE_PASSWORD_FAILURE'

export const NEW_PASSWORD_LOADING = 'NEW_PASSWORD_LOADING'
export const NEW_PASSWORD_SUCCSESS = 'NEW_PASSWORD_SUCCSESS'
export const NEW_PASSWORD_FAILURE = 'NEW_PASSWORD_FAILURE'

export const SIGN_OUT = 'SIGN_OUT'

export const EVENT_GET_LOADING = 'EVENT_GET_LOADING'
export const EVENT_GET_SUCCSESS = 'EVENT_GET_SUCCSESS'
export const EVENT_GET_FAILURE = 'EVENT_GET_FAILURE'

export const RSVP_GET_LOADING = 'RSVP_GET_LOADING'
export const RSVP_GET_SUCCSESS = 'RSVP_GET_SUCCSESS'
export const RSVP_GET_FAILURE = 'RSVP_GET_FAILURE'

export const ACC_RSVP_GET_LOADING = 'ACC_RSVP_GET_LOADING'
export const ACC_RSVP_GET_SUCCSESS = 'ACC_RSVP_GET_SUCCSESS'
export const ACC_RSVP_GET_FAILURE = 'ACC_RSVP_GET_FAILURE'

export const EVENT_DETAIL_LOADING = 'EVENT_DETAIL_LOADING'
export const EVENT_DETAIL_SUCCSESS = 'EVENT_DETAIL_SUCCSESS'
export const EVENT_DETAIL_FAILURE = 'EVENT_DETAIL_FAILURE'

export const EVENT_POST_LOADING = 'EVENT_POST_LOADING'
export const EVENT_POST_SUCCSESS = 'EVENT_POST_SUCCSESS'
export const EVENT_POST_FAILURE = 'EVENT_POST_FAILURE'

export const EVENT_DELETE_LOADING = 'EVENT_DELETE_LOADING'
export const EVENT_DELETE_SUCCSESS = 'EVENT_DELETE_SUCCSESS'
export const EVENT_DELETE_FAILURE = 'EVENT_DELETE_FAILURE'

export const EVENT_UPDATE_LOADING = 'EVENT_UPDATE_LOADING'
export const EVENT_UPDATE_SUCCSESS = 'EVENT_UPDATE_SUCCSESS'
export const EVENT_UPDATE_FAILURE = 'EVENT_UPDATE_FAILURE'

export const ARTICLES_GET_LOADING = 'ARTICLES_GET_LOADING'
export const ARTICLES_GET_SUCCSESS = 'ARTICLES_GET_SUCCSESS'
export const ARTICLES_GET_FAILURE = 'ARTICLES_GET_FAILURE'

export const ARTICLES_POST_LOADING = 'ARTICLES_POST_LOADING'
export const ARTICLES_POST_SUCCSESS = 'ARTICLES_POST_SUCCSESS'
export const ARTICLES_POST_FAILURE = 'ARTICLES_POST_FAILURE'

export const ARTICLES_UPDATE_LOADING = 'ARTICLES_UPDATE_LOADING'
export const ARTICLES_UPDATE_SUCCSESS = 'ARTICLES_UPDATE_SUCCSESS'
export const ARTICLES_UPDATE_FAILURE = 'ARTICLES_UPDATE_FAILURE'

export const ARTICLE_DETAIL_LOADING = 'ARTICLE_DETAIL_LOADING'
export const ARTICLE_DETAIL_SUCCSESS = 'ARTICLE_DETAIL_SUCCSESS'
export const ARTICLE_DETAIL_FAILURE = 'ARTICLE_DETAIL_FAILURE'

export const ARTICLE_DELETE_LOADING = 'ARTICLE_DELETE_LOADING'
export const ARTICLE_DELETE_SUCCSESS = 'ARTICLE_DELETE_SUCCSESS'
export const ARTICLE_DELETE_FAILURE = 'ARTICLE_DELETE_FAILURE'

export const PODCAST_GET_LOADING = 'PODCAST_GET_LOADING'
export const PODCAST_GET_SUCCSESS = 'PODCAST_GET_SUCCSESS'
export const PODCAST_GET_FAILURE = 'PODCAST_GET_FAILURE'

export const PODCAST_UPLOAD_LOADING = 'PODCAST_UPLOAD_LOADING'
export const PODCAST_UPLOAD_SUCCSESS = 'PODCAST_UPLOAD_SUCCSESS'
export const PODCAST_UPLOAD_FAILURE = 'PODCAST_UPLOAD_FAILURE'

export const PODCAST_UPDATE_LOADING = 'PODCAST_UPDATE_LOADING'
export const PODCAST_UPDATE_SUCCSESS = 'PODCAST_UPDATE_SUCCSESS'
export const PODCAST_UPDATE_FAILURE = 'PODCAST_UPDATE_FAILURE'

export const PODCAST_DELETE_LOADING = 'PODCAST_DELETE_LOADING'
export const PODCAST_DELETE_SUCCSESS = 'PODCAST_DELETE_SUCCSESS'
export const PODCAST_DELETE_FAILURE = 'PODCAST_DELETE_FAILURE'

export const MEMBER_GET_LOADING = 'MEMBER_GET_LOADING'
export const MEMBER_GET_SUCCSESS = 'MEMBER_GET_SUCCSESS'
export const MEMBER_GET_FAILURE = 'MEMBER_GET_FAILURE'

export const PHOTOS_GET_LOADING = 'PHOTOS_GET_LOADING'
export const PHOTOS_GET_SUCCSESS = 'PHOTOS_GET_SUCCSESS'
export const PHOTOS_GET_FAILURE = 'PHOTOS_GET_FAILURE'

export const PHOTOS_POSTALBUM_LOADING = 'PHOTOS_POSTALBUM_LOADING'
export const PHOTOS_POSTALBUM_SUCCSESS = 'PHOTOS_POSTALBUM_SUCCSESS'
export const PHOTOS_POSTALBUM_FAILURE = 'PHOTOS_POSTALBUM_FAILURE'

export const PHOTOS_POST_UPDATE_LOADING = 'PHOTOS__POST_UPDATE_LOADING'
export const PHOTOS_POST_UPDATE_SUCCSESS = 'PHOTOS__POST_UPDATE_SUCCSESS'
export const PHOTOS_POST_UPDATE_FAILURE = 'PHOTOS__POST_UPDATE_FAILURE'

export const PHOTOS_POST_DELETE_LOADING = 'PHOTOS__POST_DELETE_LOADING'
export const PHOTOS_POST_DELETE_SUCCSESS = 'PHOTOS__POST_DELETE_SUCCSESS'
export const PHOTOS_POST_DELETE_FAILURE = 'PHOTOS__POST_DELETE_FAILURE'

export const IMAGE_GET_LOADING = 'IMAGE_GET_LOADING'
export const IMAGE_GET_SUCCSESS = 'IMAGE_GET_SUCCSESS'
export const IMAGE_GET_FAILURE = 'IMAGE_GET_FAILURE'

export const IMAGE_POST_LOADING = 'IMAGE_POST_LOADING'
export const IMAGE_POST_SUCCSESS = 'IMAGE_POST_SUCCSESS'
export const IMAGE_POST_FAILURE = 'IMAGE_POST_FAILURE'

export const PROFILE_UPDATE_LOADING = 'PROFILE_UPDATE_LOADING'
export const PROFILE_UPDATE_SUCCSESS = 'PROFILE_UPDATE_SUCCSESS'
export const PROFILE_UPDATE_FAILURE = 'PROFILE_UPDATE_FAILURE'

export const PROFILE_GET_LOADING = 'PROFILE_GET_LOADING'
export const PROFILE_GET_SUCCSESS = 'PROFILE_GET_SUCCSESS'
export const PROFILE_GET_FAILURE = 'PROFILE_GET_FAILURE'

export const COVER_UPDATE_LOADING = 'COVER_UPDATE_LOADING'
export const COVER_UPDATE_SUCCSESS = 'COVER_UPDATE_SUCCSESS'
export const COVER_UPDATE_FAILURE = 'COVER_UPDATE_FAILURE'

export const PHOTO_PROFILE_UPDATE_LOADING = 'PHOTO_PROFILE_UPDATE_LOADING'
export const PHOTO_PROFILE_UPDATE_SUCCSESS = 'PHOTO_PROFILE_UPDATE_SUCCSESS'
export const PHOTO_PROFILE_UPDATE_FAILURE = 'PHOTO_PROFILE_UPDATE_FAILURE'