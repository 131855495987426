import {createStore, combineReducers, applyMiddleware} from 'redux'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import rootSaga from './saga'

import { reducerUser,reducerEvent, reducerArticles, reducerPodcast, reducerPhotos, reducerMember, reducerList, reducerProfile} from '../reducers'
// import { reducerGroup } from '../reducers/group';

const persistConfig = {
    key: 'root',
    storage
};

const rootReducer = combineReducers({
    user: reducerUser,
    events: reducerEvent,
    articles: reducerArticles,
    podcast: reducerPodcast,
    photos: reducerPhotos,
    member: reducerMember,
    list: reducerList,
    profile: reducerProfile
    // admins: reducerPayments,
    // buy: reducerBuy,
    // recipt: reducerCheckout,
    // group: reducerGroup
})

const persistedReducer = persistReducer(persistConfig, rootReducer);



const sagaMiddleware = createSagaMiddleware()
const middleware = applyMiddleware(sagaMiddleware, logger)
// const store = createStore(rootReducer, middleware)
let store = createStore(persistedReducer, middleware)
let persistor = persistStore(store);

sagaMiddleware.run(rootSaga)

export {store,persistor, sagaMiddleware}