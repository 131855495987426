const images = {
    logo: require('./img/logo.png'),
    logo_white:require('./img/logo-white.png'),
    cover:require('./img/header.png'),
    download:{
        ios: require('./img/download/appstore.png'),
        android:require('./img/download/googleplaystore.png')
    }
}

export {images};