import * as t from '../const.config'

const initialState = {
    data:[],
    detail:[],
    rsvp:{},
    action:null,
    loading:false
}

const reducerEvent = (state=initialState, action) => {
    switch(action.type){
        case t.EVENT_GET_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.EVENT_GET_SUCCSESS:
            return{
                ...state,
                action:action.type,
                data:action.data,
                loading:false
            }
        case t.EVENT_GET_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.RSVP_GET_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.RSVP_GET_SUCCSESS:
            return{
                ...state,
                action:action.type,
                rsvp:action.data,
                loading:false
            }
        case t.RSVP_GET_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.ACC_RSVP_GET_SUCCSESS:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.ACC_RSVP_GET_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.EVENT_DETAIL_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.EVENT_DETAIL_SUCCSESS:
            return{
                ...state,
                action:action.type,
                detail:action.detail,
                loading:false
            }
        case t.EVENT_DETAIL_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.EVENT_DELETE_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.EVENT_DELETE_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.EVENT_DETAIL_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.EVENT_POST_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.EVENT_POST_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.EVENT_POST_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.EVENT_UPDATE_LOADING:
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.EVENT_UPDATE_SUCCSESS:
            return{
                ...state,
                action:action.type,
                message:action.message,
                loading:false
            }
        case t.EVENT_UPDATE_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case "EVENT_CLEAR":
            return{
                ...state,
                detail:[]
            }
        default:
            return state
    }
}

export {reducerEvent}