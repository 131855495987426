import * as t from '../const.config'

const initialState = {
    data:[],
    province:[],
    city:[],
    action:null,
    loading:false
}

const reducerList = (state=initialState, action) => {
    switch(action.type){
        case t.CATEGORY_IN_LOADING :
            return{
                ...state,
                action:action.type,
                loading:true
            }
        case t.CATEGORY_IN_SUCCSESS:
            return{
                ...state,
                action:action.type,
                data:action.data,
                loading:false
            }
        case t.CATEGORY_IN_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.PROVINCE_IN_SUCCSESS:
            return{
                ...state,
                action:action.type,
                province:action.province,
                loading:false
            }
        case t.PROVINCE_IN_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        case t.CITY_IN_SUCCSESS:
            return{
                ...state,
                action:action.type,
                city:action.city,
                loading:false
            }
        case t.CATEGORY_IN_FAILURE:
            return{
                ...state,
                action:action.type,
                loading:false,
                message:action.message
            }
        default:
            return state
    }
}

export {reducerList}