import React from 'react';
import { Modal, Form, Input} from 'antd';

const ModalNewImage = ({ visible, onCreate, onCancel, imageUpload }) => {
  const [form] = Form.useForm();

  const handleChange = e => {
    if (e.target.files.length) {
      imageUpload(e.target.files[0])
    }
  }
  
  return (
    <Modal
      visible={visible}
      title="New Image"
      okText="Post"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item name="image" >
          <Input type="file" onChange={handleChange} />
          <p style={{marginTop:10}}>*maksimal file 10mb</p>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export {ModalNewImage}