import { PODCAST_DELETE_LOADING, PODCAST_GET_LOADING, PODCAST_UPDATE_LOADING, PODCAST_UPLOAD_LOADING } from "../const.config";

export const podcastGetWatcher = data =>({
    type: PODCAST_GET_LOADING,
    payload: data
})

export const podcastUploadWatcher = data =>({
    type: PODCAST_UPLOAD_LOADING,
    payload: data
})

export const podcastUpdate = data =>({
    type: PODCAST_UPDATE_LOADING,
    payload: data
})

export const podcastDeleteWatcher = data =>({
    type: PODCAST_DELETE_LOADING,
    payload: data
})