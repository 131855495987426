import React from 'react';
import { Modal, Form, Input, DatePicker, TimePicker, Upload, Button, message } from 'antd';
import { LoadingOutlined, PlusOutlined,InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const ModalUploadPodcast = ({ visible, onCreate, onCancel, imageUpload, audioUpload }) => {
  const [form] = Form.useForm();
  const [img, setImg] = React.useState(null)
  const [loading,setLoading] = React.useState(false)

  // const props = {
  //   name: 'audio',
  //   multiple: false,
  //   onChange(info) {
  //     const { status } = info.file;
  //     if (status !== 'uploading') {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (status === 'done') {
  //       message.success(`${info.file.name} file uploaded successfully.`);
  //     } else if (status === 'error') {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  // };

  // function beforeUpload(file) {
  //   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  //   if (!isJpgOrPng) {
  //     message.error('You can only upload JPG/PNG file!');
  //   }
  //   const isLt2M = file.size / 1024 / 1024 < 2;
  //   if (!isLt2M) {
  //     message.error('Image must smaller than 2MB!');
  //   }
  //   return isJpgOrPng && isLt2M;
  // }

  // function getBase64(img, callback) {
  //   const reader = new FileReader();
  //   reader.addEventListener('load', () => callback(reader.result));
  //   reader.readAsDataURL(img);
  // }

  const handleChange = e => {
    if (e.target.files.length) {
      imageUpload(e.target.files[0])
    }
  }
  const audioChange = e => {
    if (e.target.files.length) {
      audioUpload(e.target.files[0])
    }
  }
  
  return (
    <Modal
      visible={visible}
      title="Add Podcast"
      okText="Post"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
      <Form.Item
        name="audio"
        rules={[
          {
            required: true,
            message: 'Please input the audio',
          },
        ]}
      >
        <Input type="file" onChange={audioChange} />
      </Form.Item>
        <p>*maksimal file 10mb, format file audio</p>
        <Form.Item
          name="subject"
          rules={[
            {
              required: true,
              message: 'Please input the title of Podcast!',
            },
          ]}
        >
          <Input placeholder="Title" />
        </Form.Item>
        <Form.Item name="content" >
          <Input placeholder="Speaker" />
        </Form.Item>
          <p>Thumbnail</p>
        <Form.Item name="file">
          {/* <Input type="file" onChange={audioChange} /> */}
          <Input type="file" onChange={handleChange}/>
          <p style={{marginTop:10}}>*maksimal file 10mb, disarankan menggunakan rasio 1:1</p>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export {ModalUploadPodcast}