import { SIGN_IN_LOADING, SIGN_UP_LOADING, SIGN_OUT, CLEAR_DATA, FORGET_PASSWORD_LOADING, NEW_PASSWORD_LOADING, VALIDATE_PASSWORD_LOADING } from "../const.config";

export const signInWatcher = data =>({
    type: SIGN_IN_LOADING,
    payload: data
})

export const signUpWatcher = data =>({
    type: SIGN_UP_LOADING,
    payload: data
})

export const forgetPassword = data => ({
    type: FORGET_PASSWORD_LOADING,
    payload: data
})

export const newPassword = data => ({
    type: NEW_PASSWORD_LOADING,
    payload: data
})

export const validatePassword = data => ({
    type: VALIDATE_PASSWORD_LOADING,
    payload: data
})

export const signOutWatcher = () => ({
    type: SIGN_OUT
})

export const clearData = () => ({
    type: CLEAR_DATA
})