import {takeEvery, call, put} from 'redux-saga/effects'
import * as t from '../const.config'
import Axios from '../../utils/server/fetch'
import qs from 'qs'

export const watcherAuth = [
    takeEvery(t.FORGET_PASSWORD_LOADING, workerForget),
    takeEvery(t.NEW_PASSWORD_LOADING, workerNewPassword),
    takeEvery(t.VALIDATE_PASSWORD_LOADING, workerValidate),
    takeEvery(t.SIGN_IN_LOADING, workerSignIn),
    takeEvery(t.SIGN_UP_LOADING, workerSignUp)
]

const signIn = async params => {
    try{
        const data = await Axios({
            url:`api/login/?username=${params.username}&password=${params.password}&scope=public,core,posts,publish_posts,comments,publish_comments,profiles,edit_profile,taxonomies,manage_posts,manage_comments&type=web`,
            data:qs.stringify({
                CONSUMER_KEY:'119687763',
                SECRET_KEY:'FAACB79A0FEE6E85DE987C10D3559E78A6490BFC122DA4FAD5D2E7F63F08EFA6'
            })
        })
        return data
    } catch (error) {
        return error
    }
}
const signUp = async params => {
    try{
        const data = await Axios({
            method:"POST",
            url:`/api/signup/?username=${params.username}&password=${params.password}&email=${params.email}&display_name=${params.display_name}&scope=public,core,posts,publish_posts,comments,publish_comments,profiles,edit_profile,taxonomies,manage_posts,manage_comments&categories=${params.categories}&type=web&province=${params.province}&city=${params.city}`,
            // url:`/api/signup/?username=${params.username}&password=${params.password}&email=${params.email}&first_name=${params.first_name}&last_name=${params.last_name}&display_name=${params.display_name}&scope=public,core,posts,publish_posts,comments,publish_comments,profiles,edit_profile,taxonomies,manage_posts,manage_comments&type=web`,
            data:qs.stringify({
                CONSUMER_KEY:'119687763',
                SECRET_KEY:'FAACB79A0FEE6E85DE987C10D3559E78A6490BFC122DA4FAD5D2E7F63F08EFA6'
            })
        })
        return data
    } catch (error) {
        return error
    }
}


const forget = async params => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=g_lostpwd&email=${params}`,
            data:qs.stringify({
                CONSUMER_KEY:'119687763',
                SECRET_KEY:'FAACB79A0FEE6E85DE987C10D3559E78A6490BFC122DA4FAD5D2E7F63F08EFA6'
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const validatePassword = async params => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=g_validkey&login=${params.login}&key=${params.key}`,
            data:qs.stringify({
                CONSUMER_KEY:'119687763',
                SECRET_KEY:'FAACB79A0FEE6E85DE987C10D3559E78A6490BFC122DA4FAD5D2E7F63F08EFA6'
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const newPassword = async params => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=g_newpassword&password=${params.password}&repassword=${params.confirm}&id=${params.id}`,
            data:qs.stringify({
                CONSUMER_KEY:'119687763',
                SECRET_KEY:'FAACB79A0FEE6E85DE987C10D3559E78A6490BFC122DA4FAD5D2E7F63F08EFA6'
            })
        })
        return data
    } catch (error) {
        return error
    }
}

function* workerSignIn(action){
    try{
        const response = yield call(signIn, action.payload)
        if(response.data.respond === 1){
            yield put({
                type:t.SIGN_IN_SUCCSESS,
                user: response.data.result[0]
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.SIGN_IN_FAILURE,
            message:error.data.message
        })
    }
}

function* workerSignUp(action){
    try{
        const response = yield call(signUp, action.payload)
        if(response.data.respond === 1){
            yield put({
                type:t.SIGN_UP_SUCCSESS,
                user: response.data.result[0]
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.SIGN_UP_FAILURE,
            message:error.data.message
        })
    }
}

function* workerForget(action){
    try{
        const response = yield call(forget, action.payload)
        // console.log(response.data.message, "apa ya")
        if(response.data.respond === 1){
            yield put({
                type:t.FORGET_PASSWORD_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.FORGET_PASSWORD_FAILURE,
            message:error.data.message
        })
    }
}

function* workerNewPassword(action){
    try{
        const response = yield call(newPassword, action.payload)
        // console.log(response.data.message, "apa ya")
        if(response.data.respond === 1){
            yield put({
                type:t.NEW_PASSWORD_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.NEW_PASSWORD_FAILURE,
            message:error.data.message
        })
    }
}

function* workerValidate(action){
    try{
        const response = yield call(validatePassword, action.payload)
        // console.log(response.data.message, "apa ya")
        if(response.data.respond === 1){
            yield put({
                type:t.VALIDATE_PASSWORD_SUCCSESS,
                data: response.data.result
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.VALIDATE_PASSWORD_FAILURE,
            message:error.data.message
        })
    }
}