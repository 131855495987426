import React from 'react'
import { Row, Col, Button, List, Popover, Divider} from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios'
import {FileDoneOutlined,FileUnknownOutlined} from '@ant-design/icons'
import FileDownload from 'js-file-download'
import {URL as Link} from '../../utils/server/const'
import * as TYPE from '../../redux/const.config'

import {Header, Profile} from '../../components'
import {approveRSVP, getRSVP} from '../../redux/actions'
import { useParams } from 'react-router-dom'
import { colors } from '../../res';


function RSVP() {
    // const history = useHistory()
    const dispatch = useDispatch()
    const events = useSelector(state => state.events)
    const user = useSelector(state => state.user)
    const [bayar, setBayar] = React.useState(false)
    const {id} = useParams()

    const load = () => {
      let body = new FormData()
      body.append("ACCESS_TOKEN",user.user.access_token_data?.token)
      body.append("event_id",id)
      dispatch(getRSVP(body))
    }

    React.useEffect(()=> {
      load()
    },[])

    React.useEffect(() => {
      if (events.action === TYPE.ACC_RSVP_GET_SUCCSESS){
        load()
      }
    })

    const download = async () => {
      let body = new FormData()
      body.append("ACCESS_TOKEN",user.user.access_token_data?.token)
      body.append("event_id",id)
      try {
        const response = await axios.post(`${Link}/api/custom_service/?service=w_exportrsvp`, body);
        FileDownload(response.data, "rsvp.csv")
      } catch (error) {
        console.error(error);
      }
    }

    const acc = (member) => {
      let body = new FormData()
      body.append("ACCESS_TOKEN",user.user.access_token_data?.token)
      body.append("event_id", id)
      body.append("member_id", member)
      dispatch(approveRSVP(body))
    }
    return (
        <div>
            <Header />
            <div className="site-layout" style={{ paddingTop: '100px' }}>
              <Row align="top" gutter={[24,16]}>
                <Col span={6}>
                  <Profile />
                </Col>
                <Col span={12}>
                    <div style={{backgroundColor:'white', marginTop:40, borderRadius:10}}>
                        <div style={{ padding:20, display:'flex', justifyContent:"space-between", alignItems:'center', borderBottom:`2px solid #000`}}>
                            <div>
                              <h3 style={{fontWeight:'bold'}}>RSVP</h3>
                              <h4>Total <b>{events?.rsvp?.total}</b> RSVP</h4>
                            </div>
                            <Button disabled={events?.rsvp?.total == 0} onClick={download} type="default">Download RSVP</Button>
                        </div>
                        <List
                          style={{padding:15}}
                          grid={{ gutter: 16, column: 2 }}
                          dataSource={events?.rsvp?.detail}
                          renderItem={item => (
                            <Popover
                              content={
                                <div>
                                  <h3>Payment Status?</h3>
                                  <Divider style={{padding:0, margin:0}} />
                                  {
                                  item.is_approved?<p>payment has been confirmed</p>:
                                  <div>
                                    <a onClick={() =>acc(item.ID)}>Approved</a>
                                  </div>}
                                </div>
                              }
                              trigger="click"
                              placement="topLeft"
                            >
                            <List.Item style={{flexDirection:'row', display:'flex'}}>
                              {item.is_approved?<FileDoneOutlined style={{color:colors.green, fontSize:30, marginRight:5}} />:<FileUnknownOutlined style={{fontSize:30, marginRight:5}} />}
                              <List.Item.Meta
                               title={
                                 <div>
                                  {item.nama} 
                                </div> 
                              }
                               description={item.email}
                              />
                            </List.Item>
                            </Popover>
                          )}
                        />
                    </div>
                </Col>
              </Row>
              </div>
        </div>
    )
}

export {RSVP}
