import {takeEvery, call, put} from 'redux-saga/effects'
import * as t from '../const.config'
import Axios from '../../utils/server/fetch'
import qs from 'qs'

export const watcherPodcast = [
    takeEvery(t.PODCAST_GET_LOADING, workerPodcastGet),
    takeEvery(t.PODCAST_UPLOAD_LOADING, workerUploadPodcast),
    takeEvery(t.PODCAST_UPDATE_LOADING, workerUpdatePodcast),
    takeEvery(t.PODCAST_DELETE_LOADING, workerDeletePodcast)
]

const getPodcast = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_getpodcasts&search=${body.search}`,
            data:qs.stringify({
                ACCESS_TOKEN:body.token,
            })
        })
        return data
    } catch (error) {
        return error
    }
}

const uploadPodcast = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_newpodcast`,
            data:body
        })
        return data
    } catch (error) {
        return error
    }
}

const updatePodcast = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_updatepodcast`,
            data:body
        })
        return data
    } catch (error) {
        return error
    }
}

const deletePodcast = async body => {
    try{
        const data = await Axios({
            url:`/api/custom_service/?service=w_deletepodcast`,
            data:qs.stringify({
                ACCESS_TOKEN:body.token,
                post_id:body.id
            })
        })
        return data
    } catch (error) {
        return error
    }
}

function* workerPodcastGet(action){
    try{
        const response = yield call(getPodcast, action.payload)
        if(response.status === 200){
            yield put({
                type:t.PODCAST_GET_SUCCSESS,
                data: response.data.result
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.PODCAST_GET_FAILURE,
            message:error.response.data.message
        })
    }
}

function* workerUploadPodcast(action){
    try{
        const response = yield call(uploadPodcast, action.payload)
        if(response.status === 200){
            yield put({
                type:t.PODCAST_UPLOAD_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.PODCAST_UPLOAD_FAILURE,
            message:error.response.data.message
        })
    }
}

function* workerUpdatePodcast(action){
    try{
        const response = yield call(updatePodcast, action.payload)
        if(response.status === 200){
            yield put({
                type:t.PODCAST_UPDATE_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.PODCAST_UPDATE_FAILURE,
            message:error.response.data.message
        })
    }
}

function* workerDeletePodcast(action){
    try{
        const response = yield call(deletePodcast, action.payload)
        if(response.status === 200){
            yield put({
                type:t.PODCAST_DELETE_SUCCSESS,
                message: response.data.message
            })
        } else {
            throw response
        }
    } catch (error) {
        yield put({
            type: t.PODCAST_DELETE_FAILURE,
            message:error.response.data.message
        })
    }
}