import React from 'react'
import { Table, Row, Col, Button, Modal, Avatar, Input, Divider, Spin, Popover, message } from 'antd'
import { MoreOutlined,PlayCircleOutlined,DeleteOutlined,ExclamationCircleOutlined,FormOutlined } from '@ant-design/icons';

import {Header, ModalUploadPodcast, Profile} from '../../components'
import { useDispatch, useSelector } from 'react-redux';
import {podcastDeleteWatcher, podcastGetWatcher, podcastUpdate, podcastUploadWatcher} from '../../redux/actions'
import ReactPlayer from 'react-player'
import * as TYPE from '../../redux/const.config'
import { ModalUpdatePodcast } from './ModalUpdate';

function Podcast() {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isEditVisible, setIsEditVisible] = React.useState(false);
  const [data, setData] = React.useState({
    post_title:"",
    post_content:""
  });
  const [pop, setPop] = React.useState(null);
  const [imageU, setImageU] = React.useState(null)
  const [audioU, setAudioU] = React.useState(null)
  const [sound, setSoud] =React.useState(null)
  const [playing, setPlaying] =React.useState(false)
  const dispatch = useDispatch()
  const podcast = useSelector(state => state.podcast)
  const user = useSelector(state => state.user)

  
  React.useEffect(()=> {
    let body = {
      search:"",
      token:user.user.access_token_data?.token
    }
    dispatch(podcastGetWatcher(body))
  },[])

  React.useEffect(() => {
    let body = {
      token:user.user.access_token_data?.token,
      search:""
    }
    if (podcast.action === TYPE.PODCAST_UPLOAD_LOADING || podcast.action === TYPE.PODCAST_UPDATE_LOADING){
      message.loading({content:"Uploading. Please wait...", key:podcast.action})
    } else if (podcast.action === TYPE.PODCAST_DELETE_SUCCSESS || podcast.action === TYPE.PODCAST_UPLOAD_SUCCSESS|| podcast.action === TYPE.PODCAST_UPDATE_SUCCSESS){
      message.info(podcast.message)
      dispatch(podcastGetWatcher(body))
    }

  })

  const imageUpload = (img) => {
    setImageU(img)
  }
  const audioUpload = (img) => {
    setAudioU(img)
  }

  const onSearch = value => {
    let body = {
      search:value,
      token:user.user.access_token_data?.token
    }
    dispatch(podcastGetWatcher(body))
  }
  const play = (data) => {
    setPlaying(false)
    console.log(data.audio_file, "apa ya")
    setSoud(data.audio_file)
    setPlaying(true)
  }

  const openEdit = (data) => {
    setPop(data.id)
    setData({...data, post_title:data.post_title})
    setData({...data, post_content:data.post_content})
    setIsEditVisible(true)
  }
  const columns = [
    {
      title: '',
      dataIndex: ['featuredimage', 'id'],
      render: (_,data) => (
        <div style={{display:'flex', alignItems:'center'}}>
        {/* {console.log(data, "momo")} */}
        <Button style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word',margin:0, padding:0, textAlign:'start'}} type="link" onClick={() =>play(data)}>
          <Avatar style={{marginRight:12, backgroundColor:'red'}} icon={<PlayCircleOutlined />}/>
        </Button>
          <img src={data.featuredimage} style={{borderRadius:10}} width="100" height='100' />
        </div>
      )
    },
    {
      title: '',
      dataIndex: 'post_title',
    },
    {
      title: 'Listener',
      dataIndex: 'liked_count',
    },
    // {
    //   title: 'Comment',
    //   dataIndex: 'comment_count',
    // },
    // {
    //   title:'Like',
    //   dataIndex:'liked_count'
    // },
    {
      render: data => (
        <Popover placement="left" content={
            <>
            <Button onClick={() =>openEdit(data)} style={{display:'flex', alignItems:'center', textAlign:'center'}} type="link">
              <FormOutlined /> Edit
            </Button>
            <Button onClick={() => showDeleteConfirm(data.id)} style={{display:'flex', alignItems:'center', textAlign:'center'}} type="link">
              <DeleteOutlined /> Delete
            </Button>
            </>
          } trigger="click">
            <Button type="link">
              <MoreOutlined />
            </Button>
          </Popover>
      )
    }
  ];

  const showDeleteConfirm = (id) => {
    Modal.confirm({
      title: 'Are you sure delete this task?',
      icon: <ExclamationCircleOutlined />,
      content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        const body = {
          token : user.user.access_token_data?.token,
          id: id
        }
        // console.log(body, "ini adalah daatanya")
        dispatch(podcastDeleteWatcher(body))
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const onCreate = (e) => {
    let form = new FormData()
    form.append('ACCESS_TOKEN', user.user.access_token_data?.token)
    form.append('subject', e.subject)
    form.append('content', e.content?e.content:"")
    form.append('file', imageU)
    form.append('audio', audioU)
    setIsModalVisible(false)
    dispatch(podcastUploadWatcher(form))
  }

  const onUpdate = (e) => {
    let form = new FormData()
    form.append('ACCESS_TOKEN', user.user.access_token_data?.token)
    form.append('subject', e.subject)
    form.append('content', e.content?e.content:"")
    form.append('file', imageU)
    form.append('audio', audioU)
    form.append('id', pop)
    setIsEditVisible(false)
    // console.log(pop)
    dispatch(podcastUpdate(form))
  }

    return (
        <div>
            <Header />
            <div className="site-layout" style={{ paddingTop: '100px' }}>
              <Row align="top" gutter={[24,16]}>
                <Col span={6}>
                  <Profile />
                </Col>
                <Col span={12}>
                    <div style={{backgroundColor:'white', marginTop:40, borderRadius:10}}>
                        <div style={{ padding:20, display:'flex', justifyContent:"space-between"}}>
                            <Input.Search style={{width:'35%'}} placeholder="search podcast" onSearch={onSearch} enterButton />
                            <Button onClick={() => setIsModalVisible(true)} type="primary">New Podcast</Button>
                        </div>
                        <Divider/>
                        {podcast.action === TYPE.PODCAST_GET_SUCCSESS?
                        <Table style={{padding:10}} dataSource={podcast.data} columns={columns}/>:<div style={{display:'flex', justifyContent:'center', padding:50}}>
                        <Spin />
                      </div>}
                    </div>
                </Col> 
              </Row>
            </div>
            <ReactPlayer
                style={{position:'fixed', zIndex:9, bottom:0}}
                url={sound}
                width="100%"
                height="50px"
                playing={playing}
                controls={true}
                
            />
          <ModalUploadPodcast
            visible={isModalVisible}
            onCreate={onCreate}
            onCancel={() => {
              setIsModalVisible(false);
            }}
            imageUpload={imageUpload}
            audioUpload={audioUpload}
          />
          <ModalUpdatePodcast
            visible={isEditVisible}
            onCreate={onUpdate}
            onCancel={() => {
              setIsEditVisible(false);
            }}
            imageUpload={imageUpload}
            audioUpload={audioUpload}
            title={data.post_title}
            desc={data.post_content}
          />
        </div>
    )
}

export {Podcast}
