import React from 'react'
import moment from 'moment'
import { Row, Col, Button, Modal, Input, Tabs, List, Popover, Spin, message, Empty } from 'antd'
import {MoreOutlined, DeleteOutlined, ExclamationCircleOutlined,FormOutlined, ClockCircleOutlined,ContactsOutlined, LineChartOutlined} from '@ant-design/icons'

import {Header, Profile} from '../../components'
import { useDispatch, useSelector } from 'react-redux';
import {clearEvent, eventGetWatcher, eventPostWatcher, eventRemoveWatcher, eventUpdateWatcher} from '../../redux/actions'
import { useHistory } from 'react-router-dom';
import * as URL from '../../utils/routers/constant'
import * as TYPE from '../../redux/const.config'
import {CollectionCreateForm} from './'
import { EditEvent } from './ModalFormEventEdit'

function Event() {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isModalEdit, setIsModalEdit] = React.useState(false);
  const [dataEdit, setDataEdit] = React.useState({})
  const history = useHistory()
  const dispatch = useDispatch()
  const getEvent = useSelector(state => state.events)
  const user = useSelector(state => state.user)
  const [imageU, setImageU] = React.useState(null)

  React.useEffect(()=> {
    dispatch(clearEvent())
    let body = {
      search:"",
      token:user.user.access_token_data?.token
    }
    dispatch(eventGetWatcher(body))
  },[])
  
  const showModal = () => {
    setIsModalVisible(true);
  };

  const convert = (str) => {
    var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };
  
  const imageUpload = (img) => {
    setImageU(img)
  }
  const onCreate = (values) => {
    const tanggal = convert(values.tanggal_event)
    const waktu = moment(values.waktu_mulai).format("HH:mm")
    let body = new FormData()
      body.append("ACCESS_TOKEN",user.user.access_token_data?.token)
      body.append("subject", values.subject)
      body.append("content", values.content)
      body.append("tanggal", tanggal)
      body.append("lokasi", values.lokasi)
      body.append("waktu", waktu)
      body.append("file[]", imageU)
      body.append("ticket_price", values.harga?values.harga:"")
      body.append("payment_info", values.payment_info?values.payment_info:'')
      body.append("payment_pic", values.payment_pic?values.payment_pic:"")

    dispatch(eventPostWatcher(body))
    // console.log(values.payment_info?values.payment_info:'momo')
    setIsModalVisible(false);
  };

  const onUpdate = (values) => {
    const tanggal = convert(values.tanggal_event)
    const waktu = moment(values.waktu_mulai).format("HH:mm")
    let body = new FormData()
      body.append("id", dataEdit.id)
      body.append("ACCESS_TOKEN",user.user.access_token_data?.token)
      body.append("subject", values.subject?values.subject:"")
      body.append("content", values.content?values.content:"")
      body.append("tanggal", tanggal)
      body.append("lokasi", values.lokasi?values.lokasi:"")
      body.append("waktu", waktu)
      body.append("file[]", imageU)
      body.append("ticket_price", values.ticket_price?values.ticket_price:"")
      body.append("payment_info", values.payment_info?values.payment_info:"")
      body.append("payment_pic", values.payment_pic?values.payment_pic:"")
    dispatch(eventUpdateWatcher(body))
    setIsModalEdit(false);
  };
    
    const showDeleteConfirm = (id) => {
      Modal.confirm({
        title: 'Are you sure delete this task?',
        icon: <ExclamationCircleOutlined />,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          const body = {
            token : user.user.access_token_data?.token,
            id: id
          }
          dispatch(eventRemoveWatcher(body))
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }

    const edit = (item) => {
      setDataEdit({})
      setIsModalEdit(true)
      setDataEdit(item)
    }

    const onSearch = value => {
      let body = {
        search:value,
        token:user.user.access_token_data?.token
      }
      dispatch(eventGetWatcher(body))
    }

    React.useEffect(() => {
      let body = {
        search:"",
        token:user.user.access_token_data?.token
      }
      if (getEvent.action === TYPE.EVENT_DELETE_SUCCSESS || getEvent.action === TYPE.EVENT_POST_SUCCSESS || getEvent.action === TYPE.EVENT_UPDATE_SUCCSESS){
        message.success(getEvent.message)
        dispatch(eventGetWatcher(body))
      }
    })
      
    const Demo = () => (
      <div style={{ width:'100%', padding:20}}>
        {getEvent.action === TYPE.EVENT_GET_LOADING?
        <div style={{display:'flex', justifyContent:'center', padding:50}}>
        <Spin />
      </div>:
        <List
          dataSource={getEvent.data}
          renderItem={item => (
            <List.Item style={{display:'flex', justifyContent:'flex-start'}}>
              <div style={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                <div style={{display:'flex', width:'100%'}}>
                  <img height="100" width="100" style={{borderRadius:10}} src={item.featuredimage[0]?item.featuredimage[0].image:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'}/>
                  <div style={{marginLeft:10}}>
                    <Button onClick={() => history.push(`${URL.Event}/${item.id}`)} type="link" style={{margin:0, padding:0}}>
                      <h3>{item.post_title}</h3>
                    </Button>
                    <div style={{display:'flex', alignItems:'center'}}>
                      <ClockCircleOutlined style={{marginRight:5}} />
                      {item.tanggal_event}
                    </div>
                    <div style={{display:'flex', alignItems:'center'}}>
                    <ContactsOutlined style={{marginRight:5}} />
                    {item.lokasi}
                    </div>
                  </div>
                </div>
                  <Popover placement="left" content={
                    <>
                    <Button onClick={() => history.push(`${URL.Event}/${item.id}/rsvp`)} style={{display:'flex', alignItems:'center', textAlign:'center'}} type="link">
                      {/* <DeleteOutlined /> Delete */}
                      <LineChartOutlined /> RSVP
                    </Button>
                    <Button onClick={() => edit(item)} style={{display:'flex', alignItems:'center', textAlign:'center'}} type="link">
                      <FormOutlined /> Edit
                    </Button>
                    <Button onClick={() => showDeleteConfirm(item.id)} style={{display:'flex', alignItems:'center', textAlign:'center'}} type="link">
                      <DeleteOutlined /> Delete
                    </Button>
                    </>
                  } trigger="click">
                    <Button type="link">
                      <MoreOutlined />
                    </Button>
                  </Popover>
              </div>
            </List.Item>
          )}
        />}
      </div>
    );
    
    return (
        <div>
            <Header />
            <div className="site-layout" style={{ paddingTop: '100px'}}>
              <Row align="top" gutter={[24,16]}>
                <Col span={6}>
                  <Profile />
                </Col>
                <Col span={12}>
                    <div style={{backgroundColor:'white', borderRadius:10, marginTop:40}}>
                      <div style={{ padding:20, display:'flex', justifyContent:"space-between"}}>
                          <Input.Search style={{width:'35%'}} placeholder="search event" onSearch={onSearch} enterButton />
                          <Button type="primary" onClick={showModal}>New Event</Button>
                      </div>
                    </div>
                    <div style={{display:'flex',backgroundColor:'white', marginTop:20, borderRadius:10}}> 
                        <Demo/>
                    </div>
                </Col>
              </Row>
              </div>

          <CollectionCreateForm
            visible={isModalVisible}
            onCreate={onCreate}
            imageUpload={imageUpload}
            onCancel={() => {
              setIsModalVisible(false);
            }}
          />
          <EditEvent
            visible={isModalEdit}
            onCreate={onUpdate}
            imageUpload={imageUpload}
            onCancel={() => {
              setIsModalEdit(false);
            }}
            title={dataEdit.post_title}
            deskripsi={dataEdit.post_content}
            lokasi={dataEdit.lokasi}
          />
        </div>
    )
}

export {Event}
